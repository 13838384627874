// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-button {
  height: 35px;
  border: none;
  margin-bottom: 5px;
  border-radius: 5px;
  color: white;
}
.ai-button span {
  color: white !important;
}

.ai-button:active, .ai-button:focus {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.ai-indicator-autofilled {
  background-color: #4FB2D9;
}

.ai-indicator-default {
  background-color: #B2BEB5;
}

.ai-indicator-edited {
  background-color: #F16161;
}

.ai-indicator-unsure {
  background-color: #EBC03C;
}

.ai-indicator-accepted {
  background-color: #60cc70;
}

.text-bold {
  font-weight: bold;
}

.datepicker-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

mat-datepicker-toggle,
.ai-button {
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ai-components/ai-datepicker-field/ai-datepicker-field.component.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AAAJ;AACI;EACE,uBAAA;AACN;;AAGE;EACI,gFAAA;AAAN;;AAGE;EACI,yBAAA;AAAN;;AAGE;EACG,yBAAA;AAAL;;AAGE;EACG,yBAAA;AAAL;;AAGE;EACE,yBAAA;AAAJ;;AAGE;EACE,yBAAA;AAAJ;;AAGE;EACE,iBAAA;AAAJ;;AAGE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAAJ;;AAIE;;EAEE,cAAA;AADJ","sourcesContent":["\n.ai-button{\n    height: 35px;\n    border: none;\n    margin-bottom: 5px;\n    border-radius: 5px;\n    color: white;\n    span{\n      color: white !important;\n    }\n  }\n  \n  .ai-button:active, .ai-button:focus{\n      box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);\n  }\n  \n  .ai-indicator-autofilled{\n      background-color: #4FB2D9;\n  }\n  \n  .ai-indicator-default{\n     background-color: #B2BEB5;\n  }\n  \n  .ai-indicator-edited{\n     background-color: #F16161;\n  }\n\n  .ai-indicator-unsure{\n    background-color: #EBC03C;\n  }\n  \n  .ai-indicator-accepted{\n    background-color: #60cc70;\n  }\n  \n  .text-bold{\n    font-weight: bold;\n  }\n\n  .datepicker-actions {\n    display: flex;\n    align-items: center;\n    gap: 8px;  \n  }\n  \n\n  mat-datepicker-toggle, \n  .ai-button {\n    flex-shrink: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
