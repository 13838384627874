import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
} from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PropertySectionService } from "./property-section.service";
import { isAIFilled, validateStatus } from "app/utils/utils";

@Component({
  selector: "app-rb-property",
  templateUrl: "./rb-property.component.html",
  styleUrls: ["./rb-property.component.scss"],
})
export class RbPropertyComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  private descriptionChanged = new Subject<boolean>();
  private destroy$ = new Subject<void>();

  sectionObject: any = {};
  dataTypes: any;
  interestTypes: any = [];
  basicData: any = {};
  dataForm: UntypedFormGroup;
  subdivisionForm: UntypedFormGroup;
  orderDetails: any = {
    Property_County: "",
  };
  matchingRegistryStates = ["HI", "MA", "MN"];
  sectionName = "property";
  itemData = {
    Applies: true,
    Id: null,
    Sp_Id: 0,
    placeholder_state:{}
  };
  registryOptions = [
    { value: 1, label: "Recorded Land"},
    { value: 2, label: "Land Court" },
    { value: 3, label: "Other" },
  ].map((e)=>({...e, Name: e.label, Id:e.value}));
  
  isDocumentEmpty = signal<boolean>(true);
  isAIEnabled = signal<boolean>(false);
  isLoadingDataFromAI = false

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private builder: RptBuilderService,
    private cdr: ChangeDetectorRef,
    private sectionPlaceholders: PropertySectionService
  ) {
    this.resetBasic();
    this.dataForm = frmBuilder.group({
      Parcel: [null, [Validators.maxLength(255)]],
      PUD: [null],
      Development_Name: [null, [Validators.maxLength(512)]],
      Registry: [null],
      Legal_Description: [null, [Validators.maxLength(65535)]],
      Comments: [null, [Validators.maxLength(65535)]],
      Mobile_Home: [null],
      Interest_Type_Id: [null],
      Report_Lat: [null],
      Report_Lng: [null],
      Est_Comments :[null]
    });

    this.subdivisionForm = frmBuilder.group({
      Order_Subdivision: [null, [Validators.maxLength(255)]],
    });

    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.syncReportDetails(this.dataForm));

    this.subdivisionForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.updateSubdivision(this.subdivisionForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.resetBasic();

    this.descriptionChanged.pipe(
      debounceTime(1500),
      takeUntil(this.destroy$)
    ).subscribe((isSubDivision:boolean) => {
      if(!isSubDivision)this.syncReportDetails(this.dataForm, true);
      if(isSubDivision)this.updateSubdivision(this.subdivisionForm, true)
    });
  
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.dataTypes = data;
        if (data) this.interestTypes = data.Property_Interest_Types?.map(e=>({...e, ['Name']:e.Interest_Type_Name}));
      });
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initOrderdata(data));
  }

  onDescriptionChange(isSubDivision = false) {
    this.descriptionChanged.next(isSubDivision);
  }

  async handlePAIButtonClick() {
    this.isLoadingDataFromAI = true
    const sectionInfo = {
      orderId: this.orderDetails.Order_ID,
      reportId: this.itemData.Sp_Id.toString(),
      sectionId: this.itemData.Id,
      typeId: "",
      entityId : '0' ,
      sectionType: 'property' 
    };

    this.isAIEnabled.set(true);

    const response = await this.sectionPlaceholders
      .getPropertySectionInfoFromAI(sectionInfo, this.registryOptions, this.interestTypes)
    this.sectionPlaceholders.updatePlaceholdersFromResponse(response);
    this.basicData.placeholder_state = this.sectionPlaceholders.getState();
    this.patchDataFormValues(response);
    this.syncReportDetails(this.dataForm, true);
    this.updateSubdivision(this.subdivisionForm, true);
    this.isLoadingDataFromAI = false
  }

    patchDataFormValues(itemData) {
      const formUpdates = {};
      const subDivisionUpdates = {};
      Object.keys(itemData).forEach((key) => {
        switch (key) {
          case  'Parcel':{
            const value = itemData[key]!=='NA' ? itemData[key] : null;
            formUpdates[key] = value
            this.basicData[key] = value
            break;
          }
          case  'Order_Subdivision':{
            const value = itemData[key]!=='NA' ? itemData[key] : null;
            subDivisionUpdates[key] = value
            this.orderDetails[key] = value 
            break;
          }
          case  'Development_Name':{
            const value = itemData[key]!=='NA' ? itemData[key] : null;
            formUpdates[key] = value
            this.basicData[key] = value
            break;
          }
          case  'Legal_Description':{
            const value = itemData[key]!=='NA' ? itemData[key] : null;
            formUpdates[key] = value
            this.basicData[key] = value
            break;
          }
          case 'Report_Lng':{
            const value = itemData[key]!=='NA' ? itemData[key] : null;
            formUpdates[key] = value
            this.basicData[key] = value
            break;
          }
          case 'Report_Lat':{
            const value = itemData[key]!=='NA' ? itemData[key] : null;
            formUpdates[key] = value
            this.basicData[key] = value
            break;
          }
          case 'Interest_Type_Id':{
            const value = itemData[key]!=='NA' ? itemData[key] : null;
            formUpdates[key] = value
            this.basicData[key] = value
            break;
          }
          case 'Registry':{
            const value = itemData[key]!=='NA' ? itemData[key] : null;
            formUpdates[key] = value
            this.basicData[key] = value
            break;
          }
          default:
            break;
        }
      });
      this.dataForm.patchValue({
        ...formUpdates
      });
      this.subdivisionForm.patchValue({...subDivisionUpdates})
    }

  onFieldValueChange(newValue: string|moment.Moment, field: string, status='edited'): void {
      this.sectionPlaceholders.updatePlaceholderState(field, {
        value: newValue,
        status: validateStatus(status)
      });
      this.basicData.placeholder_state = this.sectionPlaceholders.getState();
      if(field === 'Order_Subdivision'){
        this.orderDetails.Order_Subdivision = newValue 
        this.updateSubdivision(this.subdivisionForm, true)
        return
      }

      this.basicData[field] = newValue;
      this.syncReportDetails(this.dataForm, true);
    }
  
    onAcceptance(field: string): void {
      this.sectionPlaceholders.acceptSuggestion(field);
      this.basicData.placeholder_state = this.sectionPlaceholders.getState();
      if(field === 'Order_Subdivision'){
        this.updateSubdivision(this.subdivisionForm, true)
      }
      this.syncReportDetails(this.dataForm, true);
    }
  
    updateDocumentCountCallback(count: number) {
      if (count > 0) {
        this.isDocumentEmpty.set(false);
        return;
      }
      this.isDocumentEmpty.set(true);
    }

  initComponent(data) {
    this.sectionObject = data;
    if (data && data.curData && data.curData.Parcel !== undefined) {
      this.basicData = data.curData;
      this.itemData.Sp_Id = this.basicData.Id;
      this.itemData.Id = null;
      if (
        this.orderDetails &&
        this.orderDetails.Order_ID !== undefined &&
        this.orderDetails.Order_ID !== ""
      )
        this.builder.manageError(
          `Property Data_${this.orderDetails.Order_ID}_0`,
          this.dataForm.status !== "VALID",
          this.dataForm.controls
        );
      if (this.basicData) {
          const combinedForm = this.frmBuilder.group({
            ...this.dataForm.controls,
            ...this.subdivisionForm.controls
          });
          this.sectionPlaceholders.initializePlaceholderState(
            combinedForm,
            this.basicData.placeholder_state
          );
         if(isAIFilled(this.basicData.placeholder_state)){
           setTimeout(()=>{
            this.isAIEnabled.set(true)
           }, 100)
         }
        }
      this.cdr.detectChanges();
    }
  }

  resetBasic() {
    this.basicData = {
      Parcel: null,
      PUD: null,
      Development_Name: null,
      Registry: null,
      Legal_Description: null,
      Comments: null,
      Mobile_Home: null,
      Interest_Type_Id: null,
      Report_Lat: null,
      Report_Lng: null,
    };
  }

  initOrderdata(data) {
    if (data && data.Property_County !== undefined) this.orderDetails = data;
  }

  buildAddress(model) {
    let completeAddress = "";
    if (model && model.Property_Address_1 !== undefined) {
      if (model.Property_Address_1) completeAddress += model.Property_Address_1;
      if (model.Property_Address_2) {
        if (completeAddress) completeAddress += " ";
        completeAddress += model.Property_Address_2;
      }
      if (model.Property_City) {
        if (completeAddress) completeAddress += " ";
        completeAddress += model.Property_City;
      }
      if (model.Property_State_Abbr) {
        if (completeAddress) completeAddress += ", ";
        completeAddress += model.Property_State_Abbr;
      }
      if (model.Property_ZipCode) {
        if (completeAddress) completeAddress += " ";
        completeAddress += model.Property_ZipCode;
      }
    }
    return completeAddress;
  }

  checkIfPresent() {
    return this.matchingRegistryStates.some(
      (state) =>
        this.orderDetails.Property_State_Abbr &&
        this.orderDetails.Property_State_Abbr.toLowerCase() ==
          state.toLowerCase()
    );
  }

  syncReportDetails(event, isForced=false) {
    if (this.orderDetails && this.orderDetails.Order_ID !== undefined)
      this.builder.manageError(
        `Property Data_${this.orderDetails.Order_ID}_0`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine || isForced) {
      this.sectionObject.curData = this.builder.trimInput(
        event,
        this.basicData
      );
      this.sectionObject.Sp_Id = this.basicData.Id;
      this.sectionObject.Derived_From = this.basicData.Derived_From;
      this.builder.saveBasicData(this.sectionObject).pipe(take(1)).subscribe();
    }
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.basicData["Id"],
      "PRT_DT",
      this.basicData.Id,
      fieldName
    );
  }

  updateSubdivision(event, isForced = false) {
    if (!event.pristine || isForced) {
      if (this.orderDetails.Order_Subdivision !== undefined)
        this.builder
          .updateSubdivisionData(
            this.orderDetails.Order_ID,
            this.orderDetails.Order_Subdivision
          )
          .subscribe();
    }
  }
}
