import { Injectable } from "@angular/core";
import {
  BaseAiSectionsService,
  SectionInfo,
} from "../base-ai-sections.service";
import { Observable } from "rxjs";

export class AssessmentSectionStateService extends BaseAiSectionsService {
  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    const attributes = {
      Year: "",
      Land: "This refers to cost of land",
      Building: "This refers to cost of Building",
      Extras: "This refers to cost of Extras",
      Total: "This refers to cost of Total",
      Parcel: "",
      // Comments: "",
      // Internal_Comments: "",
      // Applies: "",
      // Language: "",
    };
    return this.reportBuilder.getSectionDataFromAI(attributes, sectionInfo);
  }
}
