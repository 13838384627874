import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Validators, UntypedFormBuilder } from "@angular/forms";
import {
  AuthService,
  ConfigService,
  UsermessageService,
  EmailTemplatesService,
  PaiConfigService,
} from "../services";
import { SuccessComponent } from "../dialog/success/success.component";
import { ErrorDialogComponent } from "../dialog/error-dialog/error-dialog.component";
import { CONSTANTS } from "../app.constants";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-pai-config",
  templateUrl: "./pai-config.component.html",
  styleUrl: "./pai-config.component.scss",
})
export class PaiConfigComponent implements OnInit {
  @ViewChild("namefocus") namefocus: ElementRef;
  promptTemplateForm: any = {};
  promptTemplateModel: any = {};
  templateId: string = "";
  error: any = {};
  errors: any = {};
  errorMsg: any;
  constants = CONSTANTS;
  timer: any = [];
  tinyOptions: any = {
    auto_focus: false,
    branding: false,
    promotion: false,
    skin: "oxide",
    menubar: false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    toolbar:
      "undo redo | styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
    content_style: `
      body { font-size: 14px; font-family: Arial, sans-serif; }
    `,
  };
  variables = [
    // { name: "[Abstractor Name]", value: "abc xyz Inc." },
    // { name: "[Fee]", value: "$34.00" },
    // { name: "<orderid>", value: "222222" },
    // // { name: "<propertyownername>", value: "Fraser Paul" },
    // { name: "<sellersname>", value: "Joe Bas - Tony Greigh" },
    // { name: "<borrowersname>", value: "John doe - Kevin brown" },
    // { name: "<fulladdress>", value: "2777 US 7, Ferrisburgh, LA 05456" },
    // { name: "<product>", value: "Current owner search" },
    // { name: "<statecounty>", value: "Kings NY" },
    // { name: "<parcelIds>", value: "20-2214666, 20-2214667" },
    // { name: "<searchinstructions>", value: "Search instructions text" },
    // { name: "<specialinstructions>", value: "Special instructions text" },
    // {
    //   name: "<client_info>",
    //   value: `<ul><li>NC - Mecklenburg : 24 hours</li><li>NC - Wake : 12 hours</li></ul>`,
    // },
  ];
  defaultTemplate : any = null;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private frmBuilder: UntypedFormBuilder,
    public auth: AuthService,
    private config: ConfigService,
    private matDialog: MatDialog,
    private pAIConfigService: PaiConfigService,
    private userMsg: UsermessageService,
    private emailTemplateService: EmailTemplatesService ,
  ) {
    this.promptTemplateForm = frmBuilder.group({
      Template_Name: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(1024)]),
      ],
      Template_Content: [null, Validators.compose([Validators.required])],
    });
    this.promptTemplateForm.valueChanges.subscribe((data) => {
      this.error = "";
    });
  }

  ngOnInit() {
    if (this.route.snapshot.params["templateId"]) {
      this.templateId = this.route.snapshot.params["templateId"];
      this.getPromptTemplateDetails();
    }
    this.timer.push(
      setTimeout(() => {
        this.config.setSideBarNode(0);
        this.namefocus.nativeElement.focus();
      }, 0)
    );
    this.pAIConfigService.getDefaultpAIConfigTemplate()
    .subscribe((defaultTemplate) => {
      this.defaultTemplate = defaultTemplate;
    })
  }

  ngAfterViewInit() {
    if (document.querySelector("iframe"))
      document.querySelector("iframe").setAttribute("data-gramm", "false");
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  getPromptTemplateDetails() {
    this.pAIConfigService.getpAIConfigTemplateById(this.templateId).subscribe(
      (data) => {
        this.promptTemplateModel = data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  confirm() {
    if (this.route.snapshot.params["templateId"]) {
      this.updatePromptTemplate();
    } else {
      this.createPromptTemplate();
    }
  }

  getIfErrors() {
    return Object.keys(this.errors).length;
  }

  createPromptTemplate() {
    this.promptTemplateModel.User_ID = this.auth.getUserId();
    this.pAIConfigService
      .createpAIConfigTemplate(this.promptTemplateModel)
      .subscribe(
        (data) => {
          this.successMsgFunc("Prompt template created successfully");
        },
        (err) => {
          console.log(err);
          this.errorMsg = err;
        }
      );
  }

  updatePromptTemplate() {
    this.promptTemplateModel.Template_Content =
      this.emailTemplateService.sanitizeTextData(
        this.promptTemplateModel.Template_Content
      );
    this.pAIConfigService
      .updatepAIConfigTemplate(this.promptTemplateModel)
      .subscribe(
        (data) => {
          this.successMsgFunc("Prompt template updated successfully");
        },
        (err) => {
          console.log(err);
          this.openErrorPopup("Update prompt template failed.");
        }
      );
  }

  openDialog(msg) {
    let disposable = this.matDialog
      .open(SuccessComponent, {
        data: {
          title: "Success",
          message: msg,
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {
        this.router.navigate(["pAI-config/manage"]);
      });
  }

  successMsgFunc(msg) {
    this.userMsg.setSuccessMsg(msg);
    this.router.navigate(["pAI-config/manage"]);
  }
  

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {
      data: {
        message: msg,
      },
      ...this.config.getDialogOptions(),
    });
  }

  handleChangeDefault(event) {
    if (event.checked && !this.templateId)
      this.promptTemplateModel.Product_ID = null;
  }
}
