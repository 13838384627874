import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService, ConfigService, PreloaderService } from "app/services";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError, take } from "rxjs/operators";
import * as momentTimeZone from "moment-timezone";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class RptBuilderService {
  // direct: HttpClient;

  private _orderDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  public orderDetails$: Observable<any> = this._orderDetails$.asObservable();
  private _orgDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  public orgDetails$: Observable<any> = this._orgDetails$.asObservable();
  private _versionDetails$: BehaviorSubject<any> = new BehaviorSubject([]);
  public versionDetails$: Observable<any> =
    this._versionDetails$.asObservable();
  private _dataTypes$: BehaviorSubject<any> = new BehaviorSubject(null);
  public dataTypes$: Observable<any> = this._dataTypes$.asObservable();
  private _isQualiaOrder$: BehaviorSubject<any> = new BehaviorSubject(false);
  public isQualiaOrder$: Observable<any> = this._isQualiaOrder$.asObservable();
  private _isRemoteLanguage$: BehaviorSubject<any> = new BehaviorSubject(null);
  public isRemoteLanguage$: Observable<any> =
    this._isRemoteLanguage$.asObservable();
  private _commtmntItmTypes$: BehaviorSubject<any> = new BehaviorSubject(null);
  public commtmntItmTypes$: Observable<any> =
    this._commtmntItmTypes$.asObservable();

  private _basic$: BehaviorSubject<any> = new BehaviorSubject({});
  public basic$: Observable<any> = this._basic$.asObservable();
  private _searchParties$: BehaviorSubject<any> = new BehaviorSubject({});
  public searchParties$: Observable<any> = this._searchParties$.asObservable();
  private _assessments$: BehaviorSubject<any> = new BehaviorSubject({});
  public assessments$: Observable<any> = this._assessments$.asObservable();
  private _taxes$: BehaviorSubject<any> = new BehaviorSubject({});
  public taxes$: Observable<any> = this._taxes$.asObservable();
  private _vesting$: BehaviorSubject<any> = new BehaviorSubject({});
  public vesting$: Observable<any> = this._vesting$.asObservable();
  private _cot$: BehaviorSubject<any> = new BehaviorSubject({});
  public cot$: Observable<any> = this._cot$.asObservable();
  private _secInst$: BehaviorSubject<any> = new BehaviorSubject({});
  public secInst$: Observable<any> = this._secInst$.asObservable();
  private _liens$: BehaviorSubject<any> = new BehaviorSubject({});
  public liens$: Observable<any> = this._liens$.asObservable();
  private _encumbrances$: BehaviorSubject<any> = new BehaviorSubject({});
  public encumbrances$: Observable<any> = this._encumbrances$.asObservable();
  private _commitments$: BehaviorSubject<any> = new BehaviorSubject({});
  public commitments$: Observable<any> = this._commitments$.asObservable();
  private _globalCommit$: BehaviorSubject<any> = new BehaviorSubject({});
  public globalCommit$: Observable<any> = this._globalCommit$.asObservable();
  private _documents$: BehaviorSubject<any> = new BehaviorSubject([]);
  public documents$: Observable<any> = this._documents$.asObservable();
  private _generalComments$: BehaviorSubject<any> = new BehaviorSubject({});
  public generalComments$: Observable<any> =
    this._generalComments$.asObservable();

  private _isUserValid$: BehaviorSubject<any> = new BehaviorSubject(false);
  public isUserValid$: Observable<any> = this._isUserValid$.asObservable();

  private _isReportValid$: BehaviorSubject<any> = new BehaviorSubject(true);
  public isReportValid$: Observable<any> = this._isReportValid$.asObservable();
  private _deletedItems$: BehaviorSubject<any> = new BehaviorSubject({});
  public deletedItems$: Observable<any> = this._deletedItems$.asObservable();

  private reportErrors = [];
  private versionDetails = null;
  private isVersionCreation = false;

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private handler: HttpBackend,
    private preloaderService: PreloaderService
  ) {}

  resetBuilderData() {
    this._orderDetails$.next(null);
    this._orgDetails$.next(null);
    this._versionDetails$.next([]);
    this._isReportValid$.next(true);
    this.reportErrors = [];
    this.resetSections();
  }

  resetSections() {
    this._basic$.next({});
    this._searchParties$.next({});
    this._assessments$.next({});
    this._taxes$.next({});
    this._vesting$.next({});
    this._cot$.next({});
    this._secInst$.next({});
    this._liens$.next({});
    this._encumbrances$.next({});
    this._commitments$.next({});
    this._globalCommit$.next({});
    this._documents$.next([]);
    this._generalComments$.next({});
    this._deletedItems$.next({});
  }

  setUserValidity(flag) {
    this._isUserValid$.next(flag);
  }

  clearErrors() {
    this.reportErrors = [];
    this.setReportValidity(true);
  }

  manageError(id, state, controls) {
    let errIdx = this.reportErrors.findIndex((itm) => itm.key == id);
    if (errIdx !== -1) this.reportErrors.splice(errIdx, 1);
    if (state)
      this.reportErrors.push({
        key: id,
        fields: this.getInvalidFields(controls),
      });
    this.setReportValidity(this.reportErrors.length == 0);
  }

  getInvalidFields(controls) {
    const keys = Object.keys(controls);
    const keyArray = Array.from(keys);
    let invalidFields = [];
    keyArray.forEach((key) => {
      if (controls[key].status == "INVALID") invalidFields.push(key);
    });
    return invalidFields;
  }

  setReportValidity(flag) {
    this._isReportValid$.next(flag);
  }

  setQualiaOrder(flag) {
    this._isQualiaOrder$.next(flag);
  }

  setRemoteLanguage(flag) {
    this._isRemoteLanguage$.next(flag);
  }

  getDataTypes() {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/sectiontypes";
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => this._dataTypes$.next(response)),
      catchError((error: any) => throwError(error.error))
    );
  }

  getOrderDetails(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/orders/order/" + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => this._orderDetails$.next(response)),
      catchError((error: any) => throwError(error.error))
    );
  }

  getOrgDetails(orgId) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/organizations/" + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => this._orgDetails$.next(response)),
      catchError((error: any) => throwError(error.error))
    );
  }

  getAllVersions(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() +
      "/reportbuilder/versions/" +
      orderId +
      "/" +
      this.auth.getUserId();
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        this._versionDetails$.next(response);
        this.versionDetails = response;
        return response;
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  getCommitmentItemTypes(orgId) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/reportbuilder/commitmentItemTypes/" + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => this._commtmntItmTypes$.next(response)),
      catchError((error: any) => throwError(error.error))
    );
  }

  getQualiaCommitmentTypes(qualiaOrderId) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getQualiaMsURL() +
      "/orders/commitment/templates/" +
      qualiaOrderId;
    this.preloaderService.setDirectRequest();
    return this.http.get(url, httpOptions).pipe(
      map((result: any) => {
        if (result.order && result.order.order) {
          let cmtTypes = result.order.order;
          cmtTypes.accepted_requirements.map(
            (item) => (item.Index = item.type + item.code)
          );
          cmtTypes.accepted_exceptions.map(
            (item) => (item.Index = item.type + item.code)
          );
          this._commtmntItmTypes$.next(cmtTypes);
        } else
          this._commtmntItmTypes$.next({
            accepted_requirements: [],
            accepted_exceptions: [],
          });
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  getReportDetails(reportId) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/" + reportId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        this.isVersionCreation = true;
        this.resetSections();
        if (response.Search_Package)
          this.setBasicDetails(response.Search_Package, true);
        if (response.Commitment_Typings)
          this.setCommitments(response.Commitment_Typings, true);
        if (response.Global_Commitments)
          this.setGlobalCommits(response.Global_Commitments, true);
        if (response.SP_Documents) this.setDocuments(response.SP_Documents);
        if (response.SP_General_Comments)
          this.setGeneralComments(response.SP_General_Comments, true);
        setTimeout(() => {
          if (response.SP_Parties)
            this.setSearchParties(response.SP_Parties, true);
          if (response.SP_Assessments)
            this.setAssessments(response.SP_Assessments, true);
          if (response.SP_Taxes) this.setTaxes(response.SP_Taxes, true);
          if (response.SP_Chain_Of_Titles_VD)
            this.setVesting(response.SP_Chain_Of_Titles_VD, true);
          if (response.SP_Chain_Of_Titles_CT)
            this.setCot(response.SP_Chain_Of_Titles_CT, true);
          if (response.SP_Security_Instruments)
            this.setSecInst(response.SP_Security_Instruments, true);
          if (response.SP_Lien_Judgement)
            this.setLiens(response.SP_Lien_Judgement, true);
          if (response.SP_Exception_Restriction_Adverse)
            this.setEncumbrances(
              response.SP_Exception_Restriction_Adverse,
              true
            );
          setTimeout(() => {
            this.isVersionCreation = false;
            this.fetchDeletedItems();
          }, 0);
        }, 0);
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  getESTDate(date) {
    if (!date) return date;
    let estShift = momentTimeZone.tz(date, "America/New_York").isDST() ? 4 : 5;
    return moment(date)
      .utcOffset(moment(date).utcOffset())
      .add(estShift, "hours")
      .add(330, "minutes")
      .toDate();
  }

  getBasicReportData(repId) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/basic/" + repId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error.error))
    );
  }

  initBuilder(orderDetails) {
    let payload = {
      order: orderDetails,
      Admin_Id: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/basic";
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => this.setBasicDetails(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveBasicData(basicData) {
    let payload = {
      ...basicData,
      Admin_Id: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    this.preloaderService.setDirectRequest();
    let url = this.config.getBasePath() + "/reportbuilder/basic";
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) => this.setBasicDetails(response, false)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveSearchParties(item, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/searchparties";
    return this.http.put(url, item, httpOptions).pipe(
      map((response: any) => this.setSearchParties(response, isNew)),
      catchError((error: any) => throwError(error.error))
    );
  }

  updateSearchPartySortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/parties/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setSearchParties(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  getNameSearchVariations(name) {
    this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() + "/reportbuilder/party/namesearch/" + name;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveAssesments(item, isNew) {
    let payload = {
      ...item,
      Admin_Id: this.auth.getUserId(),
    };
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/assessment";
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) =>{ 
        this.setAssessments(response.Assessment, isNew);
        if(response?.CommitmentTypings) this.setCommitments(response.CommitmentTypings, true);
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  updateAssesmentSortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/assessments/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setAssessments(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveTax(item, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/tax";
    return this.http.put(url, item, httpOptions).pipe(
      map((response: any) =>{ 
        this.setTaxes(response.Taxes, isNew);
        if(response?.CommitmentTypings) this.setCommitments(response.CommitmentTypings, true);
      }),

      catchError((error: any) => throwError(error.error))
    );
  }

  updateTaxSortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/taxes/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setTaxes(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  autofillCommitmentFromAI(text, variables){
    this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    const payload = {
      templateText: text,
      info: variables,
    };
    let url = this.config.getBasePath() + "/v2/genai/autoFillCommitmentTemplate";
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => {
        console.log({response})
        return response
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  getCommitmentLogs(commitmentId){
    this.preloaderService.setDirectRequest();
    let payload = {
      commitmentId,
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/v2/genai/getCommitmentLanguageLogs";
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => {
        return response.logs
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  getSectionDataFromAI(attributes: any, { orderId, reportId, typeId, sectionId }) {
     this.preloaderService.setDirectRequest();
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: "Bearer " + this.auth.getToken(),
        }),
      };
      
      const payload = {
        attributes,
        sectionInfo: orderId,
        reportId,
        typeId,
        sectionId,
      };
      
      const url = this.config.getBasePath() + "/v2/genai/fillAttributesFromFiles";
      
      return this.http.post(url, payload, httpOptions).pipe(
        map((response: any) => {
          console.log({ response });
          return response;
        }),
        catchError((error: any) => throwError(error.error))
      );
    }

  saveVesting(item, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/vesting";
    return this.http.put(url, item, httpOptions).pipe(
      map((response: any) =>{ 
        this.setVesting(response.Vesting, isNew);
        if(response?.CommitmentTypings) this.setCommitments(response.CommitmentTypings, true);
      }),

      catchError((error: any) => throwError(error.error))
    );
  }

  updateVestingSortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/vesting/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setVesting(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveChain(item, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/chain";
    return this.http.put(url, item, httpOptions).pipe(
      map((response: any) =>{ 
        this.setCot(response.Chain, isNew);
        if(response?.CommitmentTypings) this.setCommitments(response.CommitmentTypings, true);
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  updateChainSortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/chain/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setCot(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveInstrument(item, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/instrument";
    return this.http.put(url, item, httpOptions).pipe(
      map((response: any) =>{ 
        this.setSecInst(response.Instruments, isNew);
        if(response?.CommitmentTypings) this.setCommitments(response.CommitmentTypings, true);
      }),

      catchError((error: any) => throwError(error.error))
    );
  }

  updateInstrumentSortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/instrument/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setSecInst(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveLien(item, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/lien";
    return this.http.put(url, item, httpOptions).pipe(
      map((response: any) =>{ 
        this.setLiens(response.Liens, isNew);
        if(response?.CommitmentTypings) this.setCommitments(response.CommitmentTypings, true);
      }),

      catchError((error: any) => throwError(error.error))
    );
  }

  updateLienSortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/lien/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setLiens(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveEra(item, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/era";
    return this.http.put(url, item, httpOptions).pipe(
      map((response: any) =>{ 
        this.setEncumbrances(response.ERA, isNew);
        if(response?.CommitmentTypings) this.setCommitments(response.CommitmentTypings, true);
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  updateEraSortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/era/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setEncumbrances(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  saveGlbComm(item, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/glbcomm";
    return this.http.put(url, item, httpOptions).pipe(
      map((response: any) => this.setGlobalCommits(response, isNew)),
      catchError((error: any) => throwError(error.error))
    );
  }

  updateGlbCommSortOrder(items) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/glbcomm/sort";
    return this.http.put(url, items, httpOptions).pipe(
      map((response: any) => this.setGlobalCommits(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  addDocuments(docs, spId) {
    let payload = {
      docs: docs,
      Sp_Id: spId,
      Admin_Id: this.auth.getUserId(),
    };
    this.preloaderService.setDirectRequest();
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/documents";
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => this.setDocuments(response)),
      catchError((error: any) => throwError(error.error))
    );
  }

  updateDocuments(docs, spId) {
    this.preloaderService.setDirectRequest();
    let payload = {
      docs: docs,
      Sp_Id: spId,
      Admin_Id: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/documents";
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) => this.setDocuments(response)),
      catchError((error: any) => throwError(error.error))
    );
  }

  createCommitment(commitment, spId) {
    this.preloaderService.setDirectRequest();
    let payload = {
      commitment: commitment,
      Sp_Id: spId,
      Admin_Id: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/commitment";
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => this.setCommitments(response, true)),
      catchError((error: any) => throwError(error.error))
    );
  }

  updatecommitment(commitment, dbCommitment, spId) {
    this.preloaderService.setDirectRequest();
    let payload = {
      curData: commitment,
      dbData: dbCommitment,
      Sp_Id: spId,
      Admin_Id: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/commitment";
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) => this.setCommitments(response, false)),
      catchError((error: any) => throwError(error.error))
    );
  }

  updateCommitmentPlaceholderState(placeholders, dbCommitment, spId) {
    this.preloaderService.setDirectRequest();
    let payload = {
      placeholder_state: placeholders,
      dbData: dbCommitment,
      Sp_Id: spId,
      Admin_Id: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/commitmentPlaceholders";
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) => this.setCommitments(response, false)),
      catchError((error: any) => throwError(error.error))
    );
  }


  createVersion(spId) {
    let payload = {
      Admin_Id: this.auth.getUserId(),
      Sp_Id: spId,
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/version/new";
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => {
        this.resetBuilderData();
        this._orderDetails$.next(response);
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  changeBuilderVersion(orderId, rbId) {
    let payload = {
      Admin_Id: this.auth.getUserId(),
      Order_ID: orderId,
      Builder_Id: rbId,
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/order/changeversion";
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => {
        this.resetBuilderData();
        this._orderDetails$.next(response);
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  updateGeneralComment(comment, dbComment, spId, isNew) {
    if (!isNew) this.preloaderService.setDirectRequest();
    let payload = {
      curData: comment,
      dbData: dbComment,
      Sp_Id: spId,
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/generalcomment";
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) => this.setGeneralComments(response, isNew)),
      catchError((error: any) => throwError(error.error))
    );
  }

  private setBasicDetails(data, isNew) {
    this.basic$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._basic$.next(baseData);
    });
  }

  private setSearchParties(data, isNew) {
    this.searchParties$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._searchParties$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setAssessments(data, isNew) {
    this.assessments$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._assessments$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setTaxes(data, isNew) {
    this.taxes$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._taxes$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setVesting(data, isNew) {
    this.vesting$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._vesting$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setCot(data, isNew) {
    this.cot$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._cot$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setSecInst(data, isNew) {
    this.secInst$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._secInst$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setLiens(data, isNew) {
    this.liens$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._liens$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setEncumbrances(data, isNew) {
    this.encumbrances$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._encumbrances$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setCommitments(data, isNew) {
    this.commitments$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._commitments$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setGlobalCommits(data, isNew) {
    this.globalCommit$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._globalCommit$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  private setDocuments(data) {
    this._documents$.next(data);
    if (!this.isVersionCreation) {
      this.fetchDeletedItems();
      this.orderDetails$
        .pipe(take(1))
        .subscribe((orderDetails) =>
          this.getAllVersions(orderDetails.Order_ID).subscribe()
        );
    }
  }

  private setGeneralComments(data, isNew) {
    this.generalComments$.pipe(take(1)).subscribe((baseData) => {
      baseData.dbData = data;
      if (isNew) baseData.curData = JSON.parse(JSON.stringify(data));
      this._generalComments$.next(baseData);
    });
    if (isNew && !this.isVersionCreation) this.fetchDeletedItems();
  }

  generateSearchPackage(orderId, builderId, isPreview, cmmtmntStat) {
    let payload = {
      isPreview: isPreview,
      Admin_User_ID: this.auth.getUserId(),
      generateCommitment: cmmtmntStat,
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = `${this.config.getBasePath()}/reportbuilder/searchPackage/${orderId}/${builderId}`;
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error.error))
    );
  }

  generateCommitment(orderId, builderId, isPreview) {
    let payload = {
      isPreview: isPreview,
      Admin_User_ID: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = `${this.config.getBasePath()}/reportbuilder/commitment/letter/${orderId}/${builderId}`;
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error.error))
    );
  }

  prefillReportBuilder(prefillFrom, prefillToOrder) {
    let payload = {
      prefillFrom: prefillFrom,
      prefillTo: prefillToOrder.Order_ID,
      clientRef: prefillToOrder.File_ID,
      Admin_Id: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/prefill";
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => throwError(error.error))
    );
  }

  isFieldModified(bldrId, SecType, secId, field) {
    let fieldModified = false;
    if (this.versionDetails) {
      let curVersion = this.versionDetails.find(
        (ver) => ver && ver.Sp_Id == bldrId
      );
      if (curVersion !== undefined) {
        let curSec = curVersion.Changes.find(
          (sec) => sec.id == secId && sec.key == SecType
        );
       
        fieldModified =
          curSec !== undefined &&
          curSec.fields &&
          (field == '' || curSec.fields.includes(field));

      }
    }
    return fieldModified;
  }

  isDocAdded(bldrId, secId, item) {
    let fieldModified = false;
    if (this.versionDetails) {
      let curVersion = this.versionDetails.find(
        (ver) => ver && ver.Sp_Id == bldrId
      );
      if (curVersion !== undefined) {
        let curSec = curVersion.Changes.find(
          (sec) => sec.id == secId && sec.fields && sec.fields.includes(item.Id)
        );
        fieldModified = curSec !== undefined && curSec.type == "A";
      }
    }
    return fieldModified;
  }

  isDocDeleted(bldrId, secId, item) {
    let fieldModified = false;
    if (this.versionDetails) {
      let curVersion = this.versionDetails.find(
        (ver) => ver && ver.Sp_Id == bldrId
      );
      if (curVersion !== undefined) {
        let curSec = curVersion.Changes.find(
          (sec) => sec.id == secId && sec.fields && sec.fields.includes(item.Id)
        );
        fieldModified = curSec !== undefined && curSec.type == "D";
      }
    }
    return fieldModified;
  }

  fetchDeletedItems() {
    return this.basic$.pipe(take(1)).subscribe((basicData) => {
      if (basicData.curData && basicData.curData.Id) {
        let payload = {
          Sp_Id: basicData.curData.Id,
          Derived_From: basicData.curData.Derived_From,
        };
        let httpOptions = {
          headers: new HttpHeaders({
            Authorization: "Bearer " + this.auth.getToken(),
          }),
        };
        let url =
          this.config.getBasePath() + "/reportbuilder/fetch/deleteditems";
        return (
          this.http
            .post(url, payload, httpOptions)
            .pipe(take(1))
            .subscribe((response: any) => this.setDeletedItems(response)),
          catchError((error: any) => throwError(error.error))
        );
      }
    });
  }

  setDeletedItems(data) {
    this._deletedItems$.next(data);
  }

  trimInput(event, data) {
    // Object.keys(event.controls).forEach((key) => {
    //   if (!event.controls[key].pristine && typeof data[key] == "string")
    //     data[key] = data[key].trim();
    // });
    return data;
  }

  updateSubdivisionData(orderId, subdivison) {
    let payload = {
      Order_ID: orderId,
      Order_Subdivision: subdivison,
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/subdivision";
    this.preloaderService.setDirectRequest();
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error.error))
    );
  }

  getReportFilesInZip(builderId) {    
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url = this.config.getBasePath() + "/reportbuilder/attachments/compressed/" + builderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error.error))
    );
  }
}
