// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pai-button {
  width: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  line-height: 36px !important;
  align-items: center;
}

.blue {
  background-color: #4FB2D9 !important;
}

.greyed-out {
  background-color: #D9D9D9 !important;
  cursor: pointer !important;
}

.pai-button:disabled {
  background-color: #D9D9D9 !important;
  cursor: not-allowed !important;
}

.spinner {
  margin-top: 5px;
}

.spin {
  animation: spin 1s linear infinite;
  color: white;
  font-size: 28px;
  height: 30px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/ai-components/rb-ai-header-button/rb-ai-header-button.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,uBAAA;EACA,0BAAA;EACA,qBAAA;EACA,2BAAA;EACA,2BAAA;EACA,4BAAA;EACA,mBAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,oCAAA;EACA,0BAAA;AACF;;AAEA;EACI,oCAAA;EACA,8BAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,kCAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEE;EACE;IACE,yBAAA;EACJ;AACF","sourcesContent":[".pai-button {\n  width: 36px !important;\n  height: 36px !important;\n  min-width: 36px !important;\n  padding: 0 !important;\n  border-radius: 0 !important;\n  box-shadow: none !important;\n  line-height: 36px !important;\n  align-items: center;\n}\n\n.blue {\n  background-color: #4FB2D9 !important;\n}\n\n.greyed-out {\n  background-color: #D9D9D9 !important;  \n  cursor: pointer !important;  \n}\n\n.pai-button:disabled {\n    background-color: #D9D9D9 !important;\n    cursor: not-allowed !important;\n}\n\n.spinner{\n    margin-top: 5px;\n}\n\n.spin {\n    animation: spin 1s linear infinite;\n    color: white;\n    font-size: 28px;\n    height: 30px;\n  }\n  \n  @keyframes spin {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
