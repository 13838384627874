import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { Subject, Subscription } from "rxjs";
import { LiensStateService } from "../liens-state.service";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import * as moment from "moment";
import { convertToNumber } from "app/utils/number-utils";
import { isValidDateFromAI } from "app/utils/date-utils"
import { isAIFilled } from "app/utils/utils";

@Component({
  selector: "app-rb-liens-section",
  templateUrl: "./rb-liens-section.component.html",
  styleUrls: ["./rb-liens-section.component.scss"],
  providers:[LiensStateService]
})
export class RbLiensSectionComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() dbData: any;
  @Input() itemData: any;
  @Input() itemIndex: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("firstInput") firstInput: MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionName = "Lien & Judgement";
  sectionType = "Liens & Judgements";
  sectionDocs: any = [];
  sectionTitle: any = "";
  isToggleChanged = false;
  reportDetails = {
    Derived_From: null,
  };
  orderDetails: any = {};
  lienJudmentOptions: any = [];
  lienJudgementTypeOptions: any = [];
  lienAmtSubscrptn: Subscription;
  getUSDate = new Date("01/02/1500").toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  min_Date = new Date(this.getUSDate);
  isDocumentEmpty = signal<boolean>(true);
  isAIEnabled = signal<boolean>(false);
  isLoadingDataFromAI = false

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService,
    private sectionPlaceholders: LiensStateService
  ) {
    this.dataForm = this.frmBuilder.group({
      Book_Case: [null, [Validators.maxLength(127)]],
      PG_Case: [null, [Validators.maxLength(127)]],
      Amount: [null],
      Comments: [null, [Validators.maxLength(65535)]],
      Internal_Comments: [null, [Validators.maxLength(65535)]],
      Debtor_Defendant: [null, [Validators.maxLength(2048)]],
      Type: [null, [Validators.maxLength(512)]],
      Type_ID: [null, [Validators.maxLength(512)]],
      Against_Or_Infavorof: [null, [Validators.maxLength(1024)]],
      Dated_Date: [{ value: "", disable: false }],
      Rec_Date: [{ value: "", disable: false }],
      Applies: [null],
      Language: ["good", Validators.required],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(this.itemData.Applies == 1, {
      emitEvent: false,
    });
    if (!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.lienAmtSubscrptn?.unsubscribe();
  }

  ngOnChanges(changes) {
    for (const propName in changes) {
      if (propName == "itemData") {
        this.handleAmtChange(this.itemData.Amount);
        if (this.itemData) {
          this.sectionPlaceholders.initializePlaceholderState(
            this.dataForm,
            this.itemData.placeholder_state
          );
          this.computeLienJudgementTypeOptions();
        }
       this.patchDataFormValues(this.itemData)   
       if(isAIFilled(this.itemData.placeholder_state)){
        this.isAIEnabled.set(true)
       } 
      }
    }
  }

  patchDataFormValues(itemData) {
    const formUpdates = {};
    Object.keys(itemData).forEach((key) => {
      switch (key) {
        case 'Amount':
          formUpdates[key] = convertToNumber(itemData[key]);
          break;
        case 'Language':
          formUpdates[key] = itemData.Language || "good";
          break;
        case 'Applies':
          formUpdates[key] = itemData.Applies || false;
          break;
        case 'Type_ID':
          break;
        default:
          formUpdates[key] = itemData[key] && itemData[key] !== 'NA' || null;
          break;
      }
    });
    this.dataForm.patchValue({
      ...formUpdates
    });
  }
  

  computeLienJudgementTypeOptions() {
    let options = [];
    if (this.itemData.Entity_ID == 1) {
      options = this.getOptionsForLien();
    }
    if (this.itemData.Entity_ID === 2) {
      options = this.getOptionsForJudgment();
    }
    this.lienJudgementTypeOptions = options.map((e) => ({
      ...e,
      Name: e.Type,
    }));
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          let selectedEntity = data.SP_Lien_Judgement_Entities.find(
            (entity) => entity.Id == this.itemData.Entity_ID
          );
          if (selectedEntity !== undefined)
            this.sectionTitle = selectedEntity.Entity;
          this.lienJudmentOptions = data.SP_Lien_Judgement_Types;
          this.computeLienJudgementTypeOptions()
        }
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
   if(this.builder.basic$) this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined) {
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          ) {
            this.builder.manageError(
              `Lien_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
              this.dataForm.status !== "VALID",
              this.dataForm.controls
            );
            this.cdr.detectChanges();
            this.handleMandatoryFldStates();
          }
        }
      });
    this.sectionPlaceholders.initializePlaceholderState(
      this.dataForm,
      this.itemData.placeholder_state
    );
    this.cdr.detectChanges();

    if (this.itemData.Entity_ID == 3)
      this.dataForm.addControl("Type_ID", new UntypedFormControl(null));
    else
      this.dataForm.addControl(
        "Type_ID",
        new UntypedFormControl(null, [Validators.required])
      );
    this.lienAmtSubscrptn = this.dataForm
      .get("Amount")
      .valueChanges.subscribe((value) => {
        this.handleAmtChange(value);
      });
    this.setFieldAsMarked("Type_ID");
    this.setFieldAsMarked("Comments");
    if (this.dataForm.controls["Type_ID"])
      this.manageAppliesTgleChange(
        this.dataForm.controls["Type_ID"],
        this.itemData.Entity_ID == 3 ? [] : [Validators.required],
        []
      );
  }

  async handlePAIButtonClick() {
    this.isLoadingDataFromAI = true
    const sectionInfo = {
      orderId: this.orderDetails.Order_ID,
      reportId: this.itemData.Sp_Id,
      sectionId: this.itemData.Id,
      typeId: this.sectionType,
      entityId:this.itemData.Entity_ID,
      sectionType: 'liens_and_judgements'
    };
    this.isAIEnabled.set(true)
    const response = await this.sectionPlaceholders
      .getLiensSectionInfoFromAI(sectionInfo, this.lienJudgementTypeOptions)
      .toPromise();
    this.sectionPlaceholders.updatePlaceholdersFromResponse(response);
    this.updateFormFromAIResponse(response);
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.patchDataFormValues(response)
    const formattedData = this.dataForm.value
    this.itemData = {...this.itemData, ...formattedData, Type_ID: this.itemData.Type_ID}
    this.saveItem(this.dataForm, true);
    this.isLoadingDataFromAI = false
  }

 updateFormFromAIResponse(response) {
    //check for valid dropdown option
    if(response.Type_ID){
      const value = response.Type_ID;
      if (value !== "NA") {
        const selectedOption = this.lienJudgementTypeOptions.find(
          (e) => e.Name.toLowerCase() === value.toLowerCase()
        );
        if (selectedOption) {
          this.sectionPlaceholders.updatePlaceholderState(
            "Type_ID",
            { value: selectedOption.Id, status: "autofilled" }
          );
          this.itemData.Type_ID = selectedOption.Id
          this.dataForm.controls.Type_ID.setValue(selectedOption.Id, {emit:false})
        }
      } else {
        this.sectionPlaceholders.updatePlaceholderState(
          "Type_ID",
          { value: null, status: "unsure" }
        );
      }
    }

    if(response.Dated_Date){
      const isValid = isValidDateFromAI(response.Dated_Date)
      if(isValid){
        this.sectionPlaceholders.updatePlaceholderState(
          "Dated_Date",
          { value: response.Dated_Date, status: "autofilled" }
        );
        this.itemData.Dated_Date = response.Dated_Date
      }
      else{
        this.sectionPlaceholders.updatePlaceholderState(
          "Dated_Date",
          { value: null, status: "unsure" }
        );
      }
    }
    if(response.Rec_Date){
      const isValid = isValidDateFromAI(response.Rec_Date)
      if(isValid){
        this.sectionPlaceholders.updatePlaceholderState(
          "Rec_Date",
          { value: response.Rec_Date, status: "autofilled" }
        );
        this.itemData.Rec_Date = response.Rec_Date
      }
      else{
        this.sectionPlaceholders.updatePlaceholderState(
          "Rec_Date",
          { value: null, status: "unsure" }
        );
      }
    }
  }

  validateStatus(
    input: string
  ): "autofilled" | "default" | "edited" | "accepted" {
    if (
      input === "autofilled" ||
      input === "default" ||
      input === "edited" ||
      input === "accepted"
    ) {
      return input;
    }
    return "default";
  }

  onFieldValueChange(
    newValue: string | moment.Moment,
    field: string,
    status = "edited"
  ): void {
    this.sectionPlaceholders.updatePlaceholderState(field, {
      value: newValue,
      status: this.validateStatus(status),
    });
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.itemData[field] = newValue ? newValue : null;
  }

  onAcceptance(field: string): void {
    this.sectionPlaceholders.acceptSuggestion(field);
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.saveItem(this.dataForm, true);
  }

  updateDocumentCountCallback(count: number) {
    if (count > 0) {
      this.isDocumentEmpty.set(false);
      return;
    }
    this.isDocumentEmpty.set(true);
  }

  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
    this.handleMandatoryFldStates();
    this.isToggleChanged = true;
  }

  saveItem(event, forced = false) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `Lien_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine || forced) {
      this.itemData.placeholder_state = this.sectionPlaceholders.getState();
      let payload = {
        curData: this.builder.trimInput(event, this.itemData),
        dbData: this.dbData,
        Sp_Id: this.reportDetails["Id"],
        Derived_From: this.reportDetails["Derived_From"],
        toggleControl: {
          changed: this.isToggleChanged,
          state: this.itemData.Applies,
        },
      };
      this.isToggleChanged = false;
      this.builder.saveLien(payload, false).pipe(take(1)).subscribe();
    }
  }

  deleteItem() {
    this.matDialog
      .open(ConfirmComponent, {
        data: {
          title: `Delete ${this.sectionTitle}`,
          message: `Are you sure you want to delete this ${this.sectionTitle.toLowerCase()} ?`,
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `Lien_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder.saveLien(payload, true).pipe(take(1)).subscribe();
        }
      });
  }

  handleCommitmentError(isValid) {
    this.dataForm.controls["Language"].setValue(isValid ? "good" : null);
  }

  getOptionsForLien() {
    return this.lienJudmentOptions.filter(
      (option) => option.Type_ID == 1 //53 - Other lien
    );
  }

  getOptionsForJudgment() {
    return this.lienJudmentOptions.filter(
      (option) => option.Type_ID == 2 //64 - Other lien
    );
  }

  onOptionChange(event) {
    // this.dataForm.controls['Type'].reset();
  }

  setFieldAsMarked(fieldKey) {
    var keyIndex = Object.keys(this.dataForm.controls).findIndex(
      (key) => key == fieldKey
    );
    if (keyIndex > -1) {
      let key = Object.keys(this.dataForm.controls)[keyIndex];
      this.dataForm.get(key).markAsTouched();
    }
  }

  handleAmtChange(value) {
    if (value && value > 0) {
      this.dataForm
        .get("Comments")
        .setValidators([Validators.maxLength(65535)]);
    } else {
      this.dataForm
        .get("Comments")
        .setValidators([Validators.maxLength(65535), Validators.required]);
    }
    this.dataForm.get("Comments").updateValueAndValidity();
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "LEN_JG",
      this.itemData.Id,
      fieldName
    );
  }

  getPippinDateJudge(event) {
    this.itemData.Dated_Date = event.Pippin_Date;
  }

  getPippinDateJudgeRec(event) {
    this.itemData.Rec_Date = event.Pippin_Date;
  }

  manageAppliesTgleChange(control, onValidators, offValidators) {
    if (!this.itemData.Applies) {
      control.setValidators(offValidators);
      control.setErrors(null);
      this.builder.manageError(
        `Lien_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        false,
        {}
      );
    } else {
      control.setValidators(onValidators);
      this.builder.manageError(
        `Lien_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        this.dataForm.status !== "VALID",
        this.dataForm.controls
      );
    }
    control.updateValueAndValidity();
  }

  handleMandatoryFldStates() {
    if (this.dataForm.controls["Type_ID"])
      this.manageAppliesTgleChange(
        this.dataForm.controls["Type_ID"],
        this.itemData.Entity_ID == 3 ? [] : [Validators.required],
        []
      );
    if (this.itemData.Amount && this.itemData.Amount > 0)
      this.manageAppliesTgleChange(
        this.dataForm.controls["Comments"],
        [Validators.maxLength(65535)],
        [Validators.maxLength(65535)]
      );
    else
      this.manageAppliesTgleChange(
        this.dataForm.controls["Comments"],
        [Validators.maxLength(65535), Validators.required],
        [Validators.maxLength(65535)]
      );
  }
}
