// base-ai-sections.service.ts
import { Injectable, signal, computed, Signal } from "@angular/core";
import {RptBuilderService} from 'app/report-builder/rpt-builder.service'
import { Observable } from "rxjs";
import { FormGroup } from "@angular/forms";
import { Moment } from "moment";

export interface SectionInfo {
  orderId: string;
  reportId: string;
  sectionId: string;
  typeId: string;
}

export interface PlaceholderState {
  value: string | number | Moment;
  originalValue: string;
  suggestedValue?: string;
  status: "default" | "edited" | "accepted" | "autofilled" | "unsure";
  isAccepted: boolean;
  originalPlaceholderForFieldSet: string;
}

export interface PlaceholderStateMap {
  [key: string]: PlaceholderState;
}

@Injectable()
export abstract class BaseAiSectionsService {
  public placeholderState = signal<PlaceholderStateMap>({});
  readonly placeholderState$ = this.placeholderState.asReadonly();



  readonly autofilledFields = computed(() => 
    Object.entries(this.placeholderState())
      .filter(([_, state]) => state.status === 'autofilled')
      .map(([field]) => field)
  );

  readonly acceptedFields = computed(() => 
    Object.entries(this.placeholderState())
      .filter(([_, state]) => state.status === 'accepted')
      .map(([field]) => field)
  );

  constructor(
    protected reportBuilder: RptBuilderService
  ) {}

  initializePlaceholderState(form: FormGroup, existingPlaceholderInDB?: any): PlaceholderStateMap {
    const initialState: PlaceholderStateMap = {};
    if(existingPlaceholderInDB){
      Object.keys(existingPlaceholderInDB).forEach(key => {
        const state = existingPlaceholderInDB[key]
        initialState[key] = {
          ...state
        };
      });
      this.placeholderState.set(initialState);
      return initialState;
    }
    Object.keys(form.controls).forEach(key => {
      initialState[key] = {
        value: "",
        originalValue: "",
        status: "default",
        isAccepted: false,
        originalPlaceholderForFieldSet: ""
      };
    });
    this.placeholderState.set(initialState);
    return initialState;
  }

  updatePlaceholderState(field: string, updates: Partial<PlaceholderState>): void {
    this.placeholderState.update(state => ({
      ...state,
      [field]: {
        ...state[field],
        ...updates
      }
    }));
  }

  // Abstract method that must be implemented by child classes
  abstract getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any>;

  public updatePlaceholdersFromResponse(response: any): void {
    const currentState = this.placeholderState();
    const updatedState = { ...currentState };

    Object.entries(response).forEach(([field, value]) => {
      if (updatedState[field]) {
        updatedState[field] = {
          ...updatedState[field],
          suggestedValue: value as string,
          value: value as string == "NA" ? null : value as string,
          status: value as string == "NA"? "unsure": "autofilled"
        };
      }
    });

    this.placeholderState.set(updatedState);
  }

  acceptSuggestion(field: string): void {
    const currentState = this.placeholderState();
    if (currentState[field]) {
      this.updatePlaceholderState(field, {
        value: currentState[field].value,
        status: "accepted",
        isAccepted: true
      });
    }
  }

  rejectSuggestion(field: string): void {
    this.updatePlaceholderState(field, {
      status: "default",
      isAccepted: false
    });
  }

  markAsEdited(field: string, value: string): void {
    this.updatePlaceholderState(field, {
      value,
      status: "edited",
      isAccepted: false
    });
  }

  getState(): PlaceholderStateMap {
    return this.placeholderState();
  }


}