import { Injectable } from '@angular/core';
import { BaseAiSectionsService, SectionInfo } from '../base-ai-sections.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertySectionService extends BaseAiSectionsService {

  attributes = {}
  
  getPropertySectionInfoFromAI(sectionInfo:SectionInfo, dropdownOptions){
    const names = dropdownOptions.map(e=>e.Name)
    this.attributes = {
      Parcel: '',
      Development_Name: '',
      Registry: '',
      Legal_Description: '',
      Instrument_Type_ID: `select any one value from this list :- ${JSON.stringify(names)}`,
      Report_Lat: '',
      Report_Lng: '',
      Order_Subdivision: ''
    }
    return this.getSectionInfoFromAI(sectionInfo)
  }

  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    return this.reportBuilder.getSectionDataFromAI(this.attributes, sectionInfo); 
  } 
}
