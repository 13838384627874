import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import * as moment from "moment";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-rb-glob-comm-section",
  templateUrl: "./rb-glob-comm-section.component.html",
  styleUrls: ["./rb-glob-comm-section.component.scss"],
})
export class RbGlobCommSectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() dbData: any;
  @Input() itemData: any;
  @Input() itemIndex: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('firstInput') firstInput : MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionTitle: any = "";
  reportDetails = {
    Derived_From: null,
  };
  orderDetails: any = {};
  commitmentItemTypes: any;
  fieldSet = [];
  placeHolderPairs = [
    ["_", "_"],
    ["{", "}"],
  ];
  isQualiaOrder = false;
  isRemoteLanguage = null;
  pippinExpsItems: any = [];
  pippinReqsItems: any = [];
  qualiaExpsItems: any = [];
  qualiaReqsItems: any = [];
  errors: any = [];
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog:MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService
  ) {
    this.dataForm = this.frmBuilder.group({
      Category: [null],
      Type_Id: [null],
      Statement: [null],
      Language: [null],
      Applies: [null],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(this.itemData.Applies == 1, {
      emitEvent: false,
    });
    if (!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
    this.validateLanguage(this.itemData.Language);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          let selectedEntity = data.Commitment_Typing_Categories.find(
            (entity) => entity.Id == this.itemData.Category_Id
          );
          if (selectedEntity !== undefined)
            this.sectionTitle = selectedEntity.Name;
        }
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined){
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          ){
              this.builder.manageError(
                `GComm_${this.orderDetails.Order_ID}__${this.itemData.Id}`,
                this.dataForm.status !== "VALID",
                this.dataForm.controls
              );
                this.cdr.detectChanges();
            }
        }
      });
    this.builder.isRemoteLanguage$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.isRemoteLanguage = data;
        this.validateLanguage(this.itemData["Language"]);
      });
    this.builder.isQualiaOrder$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.isQualiaOrder = data;
      });
    this.builder.commtmntItmTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.commitmentItemTypes = data;
        if (this.commitmentItemTypes && !this.isQualiaOrder) {
          this.pippinReqsItems = this.commitmentItemTypes.filter(
            (item) => item && item.Commitment_Type && item.Commitment_Type == 1
          );
          this.pippinExpsItems = this.commitmentItemTypes.filter(
            (item) => item && item.Commitment_Type && item.Commitment_Type == 2
          );


          if (this.pippinReqsItems.length > 0 && this.itemData) {
            this.pippinReqsItems = [
              ...this.pippinReqsItems,
              {
                Category_Id: 1,
                Cust_Commit_Type_Id: "OTHER",
                Id: 9999, // Unique Id
                Language: "Other",
                Mandatory_Fields: null,
                Pippin_Company_Id: null,
                Type_Name: this.isRemoteLanguage? "Other":"Other (Will Not Be Transmitted)",
              },
            ];
          }
      
          if (this.pippinExpsItems.length > 0 && this.itemData) {
            this.pippinExpsItems = [
              ...this.pippinExpsItems,
              {
                Category_Id: 2,
                Cust_Commit_Type_Id: "OTHER",
                Id: 9999, // Unique Id
                Language: "Other",
                Mandatory_Fields: null,
                Pippin_Company_Id: null,
                Type_Name: this.isRemoteLanguage? "Other":"Other (Will Not Be Transmitted)",
              },
            ];
          }

         
        }
        else if (this.commitmentItemTypes && this.isQualiaOrder) {
          if(this.commitmentItemTypes.accepted_requirements) {
            this.qualiaReqsItems = this.commitmentItemTypes.accepted_requirements;
          }

          if(this.commitmentItemTypes.accepted_exceptions) {
            this.qualiaExpsItems = this.commitmentItemTypes.accepted_exceptions;
          }

          if (this.qualiaReqsItems.length > 0 && this.itemData) {
            this.qualiaReqsItems = [
              ...this.qualiaReqsItems,
              {
                Index: "OTHER",
                code: this.isRemoteLanguage ? "Other" : "Will Not Be Transmitted",
                description: "Other",
                sampleText: "",
                type: "other"
              }
            ];
          }
      
          if (this.qualiaExpsItems.length > 0 && this.itemData) {
            this.qualiaExpsItems = [
              ...this.qualiaExpsItems,
              {
                Index: "OTHER",
                code: this.isRemoteLanguage ? "Other" : "Will Not Be Transmitted",
                description: "Other",
                sampleText: "",
                type: "other"
              }
            ];
          }
      }
      });

    this.getFieldSet();
    this.validateForm();
    this.cdr.detectChanges();
    this.dataForm.controls["Language"].valueChanges.subscribe((value) => {
      this.validateLanguage(value);
    });

   
  }

  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
  }

  saveItem(event) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `GComm_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine) {
      this.savetoDB();
    }
  }

  savetoDB() {
    this.itemData.Language = this.itemData.Language.trim();
    let payload = {
      curData: this.itemData,
      dbData: this.dbData,
      Sp_Id: this.reportDetails["Id"],
      Derived_From: this.reportDetails["Derived_From"],
    };
    this.builder.saveGlbComm(payload, false).pipe(take(1)).subscribe();
  }

  deleteItem() {
    this.matDialog
      .open(
        ConfirmComponent,
        {data:{
          title: `Delete ${this.sectionTitle}`,
          message: `Are you sure you want to delete this ${this.sectionTitle.toLowerCase()} ?`,
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `GComm_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder.saveGlbComm(payload, true).pipe(take(1)).subscribe();
        } else {
          console.log("Closed", res);
        }
      });
  }

  fieldChangeHandler(field, event) {
    if (field && field != undefined) {
      field.val = event;
      this.setLanguageText();
      this.validateForm();
    }
  }

  validateForm() {
    if (this.isRemoteLanguage) this.validateLanguage(this.itemData["Language"]);
    else if (this.isRemoteLanguage !== null) {
      if (typeof this.fieldSet !== "object")
        this.fieldSet = JSON.parse(this.fieldSet);
      if (this.fieldSet.length > 0) {
        console.log("this.fieldSet", this.fieldSet);
        if (typeof this.fieldSet !== "object")
          this.fieldSet = JSON.parse(this.fieldSet);
        if (typeof this.fieldSet == "object") {
          let errFieldIndex = this.fieldSet.findIndex((fld) => fld.val === "");
          if (errFieldIndex > -1) {
            setTimeout(() => {
              if (!this.dataForm.controls["Language"].hasError("incorrect")) {
                this.dataForm.controls["Language"].setErrors({
                  incorrect: true,
                });
                this.dataForm.controls["Language"].markAsTouched();
              }
            }, 0);
          } else {
            this.dataForm.controls["Language"].setErrors(null);
          }
        }
      }
    }
  }

  setLanguageText() {
    let newLang = this.itemData["Statement"];
    this.placeHolderPairs.forEach((ph) => {
      let curLang = newLang;
      newLang = "";
      let elmStart = false;
      let elm = "";
      let lastChar = "";
      curLang.split("").forEach((ch) => {
        if (elmStart) {
          elm += ch;
          lastChar = ch;
          if (ch == ph[1]) {
            elmStart = false;
            if (elm.length > 2) {
              lastChar = "";
              let field = this.itemData.Field_Set.find((fld) => fld.key == elm);
              if (field.val == "" || !field.val) newLang += `${field.key}`;
              else {
                let newVal = field.val;
                if (this.isDateValue(newVal))
                  newVal = moment(newVal).format("M/D/YYYY");
                newLang += `${newVal}`;
              }
            } else newLang += elm;
          }
        } else if (ch == ph[0] && lastChar !== ph[0]) {
          lastChar = ch;
          elmStart = true;
          elm = ch;
        } else {
          lastChar = ch;
          newLang += ch;
        }
      });
    });
    this.itemData["Language"] = newLang;
    this.savetoDB();
  }

  isDateValue(inpDate: any) {
    return inpDate instanceof Date && !isNaN(inpDate.valueOf());
  }

  getEntities() {
    return this.isQualiaOrder
      ? this.getQualiaEntities()
      : this.getPippinEntities();
  }

  getQualiaEntities() {
    if (this.commitmentItemTypes) {
      if (
        this.itemData &&
        this.itemData["Category_Id"] == 1 &&
        this.commitmentItemTypes.accepted_requirements
      )
        return this.qualiaReqsItems;
      else if (
        this.itemData &&
        this.itemData["Category_Id"] == 2 &&
        this.commitmentItemTypes.accepted_exceptions
      )
        return this.qualiaExpsItems;
      else return [];
    } else return [];
  }

  getPippinEntities() {
    if (this.commitmentItemTypes && this.commitmentItemTypes.length > 0) {
      if (this.itemData && this.itemData["Category_Id"] == 1)
        // return this.commitmentItemTypes.filter(
        //   (itm) => itm.Commitment_Type == 1
        // );
        return this.pippinReqsItems;
      else if (this.itemData && this.itemData["Category_Id"] == 2)
        // return this.commitmentItemTypes.filter(
        //   (itm) => itm.Commitment_Type == 2
        // );
        return this.pippinExpsItems;
      else return [];
    } else return [];
  }

  getSearchKey() {
    return this.isQualiaOrder ? ["description", "code"] : ["Type_Name", "Cust_Commit_Type_Id"];
  }

  getSelectKey() {
    return this.isQualiaOrder ? "Index" : "Cust_Commit_Type_Id";
  }

  optionChangeHandler(event) {
    if (event) {
      if (this.isQualiaOrder) this.handleQualiaOptionChange(event);
      else this.handlePippinOptionChange(event);
      this.validateLanguage(this.itemData["Language"]);
    }
  }

  handleQualiaOptionChange(event) {
    let optionList =
      this.itemData["Category_Id"] == 1
        ? this.commitmentItemTypes.accepted_requirements
        : this.commitmentItemTypes.accepted_exceptions;
    let matchedIndex = optionList.findIndex(
      (itemTypes) => itemTypes.Index == event
    );
    if (matchedIndex > -1) {
      this.itemData["Statement"] = optionList[matchedIndex].sampleText;
      this.itemData["Language"] = optionList[matchedIndex].sampleText;
    }
  }

  handlePippinOptionChange(event) {
    let matchedIndex = this.commitmentItemTypes.findIndex(
      (itemTypes) =>
        itemTypes.Cust_Commit_Type_Id == event &&
        itemTypes.Commitment_Type == this.itemData["Category_Id"]
    );
    if (matchedIndex > -1) {
      this.itemData["Statement"] =
        this.commitmentItemTypes[matchedIndex].Language;
      this.itemData["Language"] =
        this.commitmentItemTypes[matchedIndex].Language;
      this.getLangFieldSet(this.commitmentItemTypes[matchedIndex].Language);
    }else{
      this.itemData["Statement"] = "";
      this.itemData["Language"] = "";
      this.itemData["Field_Set"] = [];
      this.fieldSet = [];
      this.errors=[]
    }
  }

  getLangFieldSet(lang) {
    this.itemData["Field_Set"] = [];
    this.itemData["Language"] = lang;
    this.placeHolderPairs.forEach((ph) => {
      let elmStart = false;
      let elm = "";
      let lastChar = "";
      lang.split("").forEach((ch) => {
        if (elmStart) {
          elm += ch;
          lastChar = ch;
          if (ch == ph[1]) {
            elmStart = false;
            let field = this.itemData["Field_Set"].find(
              (fld) => fld.key == elm
            );
            if (elm.length > 2 && field == undefined) {
              lastChar = "";
              this.itemData["Field_Set"].push({ key: elm, val: "" });
            }
          }
        } else if (ch == ph[0] && lastChar !== ph[0]) {
          lastChar = ch;
          elmStart = true;
          elm = ch;
        } else lastChar = ch;
      });
    });
    this.getFieldSet();
  }

  getFieldSet() {
    if (this.itemData && this.itemData["Field_Set"]) {
      if (typeof this.itemData["Field_Set"] == "string") {
        if (this.itemData["Field_Set"].length == 4)
          this.itemData["Field_Set"] = [];
        else
          this.itemData["Field_Set"] = JSON.parse(this.itemData["Field_Set"]);
      }
      this.fieldSet = this.itemData["Field_Set"];
      if (typeof this.fieldSet !== "object")
        this.fieldSet = JSON.parse(this.fieldSet);
    }
  }

  isEntitiesPresent() {
    return this.isQualiaOrder
      ? this.getQualiaEntities().length > 0
      : this.getPippinEntities().length > 0;
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "ADD_COM",
      this.itemData.Id,
      ""
    );
  }

  validateLanguage(val) {
    if (val && val !== undefined && this.isRemoteLanguage) {
      this.errors = [];
      let lastChar = "";
      this.placeHolderPairs.forEach((ph) => {
        let elmStart = false;
        let elm = "";
        val.split("").forEach((ch) => {
          if (elmStart) {
            elm += ch;
            lastChar = ch;
            if (ch == ph[1]) {
              elmStart = false;
              if (elm.length > 2) {
                lastChar = "";
                this.errors.push(elm);
              }
            }
          } else if (ch == ph[0] && lastChar !== ph[0]) {
            lastChar = ch;
            elmStart = true;
            elm = ch;
          } else lastChar = ch;
        });
      });
      if (this.dataForm.controls["Language"]) {
        if (
          this.errors.length == 0
        ) {
          this.dataForm.controls["Language"].setErrors(null);
        } else if (
          this.errors.length > 0 &&
          this.dataForm.controls["Language"].errors == null
        ) {
          setTimeout(() => {
            this.dataForm.controls["Language"].setErrors({ incorrect: true });
            this.dataForm.controls["Language"].markAsTouched();
          }, 0);
        }
      }
    }
  }
}
