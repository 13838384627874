import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { Subject } from "rxjs";
import { PartySectionService } from "../party-section.service";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { isAIFilled } from "app/utils/utils";

@Component({
  selector: "app-rb-party-section",
  templateUrl: "./rb-party-section.component.html",
  styleUrls: ["./rb-party-section.component.scss"],
  providers:[PartySectionService]
})
export class RbPartySectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() dbData: any;
  @Input() itemIndex: any;
  @Input() itemData: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("firstInput") firstInput: MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionName = "Search Party";
  sectionType = "Search Parties";
  sectionDocs: any = [];
  sectionTitle: any = "";
  reportDetails = {
    Derived_From: null,
  };
  isDocumentEmpty = signal<boolean>(true);
  isAIEnabled = signal<boolean>(false);
  isLoadingDataFromAI = false;
  
  orderDetails: any = {};
  searchVariations: any = [];
  deletedAliases: any = [];
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService,
    private sectionPlaceholders: PartySectionService
  ) {
    this.dataForm = this.frmBuilder.group({
      Last_Name: [null, [Validators.maxLength(512)]],
      Middle_Name: [null, [Validators.maxLength(512)]],
      AKA: [null, [Validators.maxLength(512)]],
      First_Business_Name: [null, [Validators.maxLength(2048)]],
      Probate_Court: [null, [Validators.maxLength(2048)]],
      Civil_Court: [null, [Validators.maxLength(2048)]],
      Divorce_Court: [null, [Validators.maxLength(2048)]],
      Comments: [null, [Validators.maxLength(65535)]],
      Internal_Comments: [null, [Validators.maxLength(65535)]],
      Applies: [null],
      Is_Current_Owner: [null],
      Alias: [[]],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(this.itemData.Applies == 1, {
      emitEvent: false,
    });
    if (!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          let selectedEntity = data.SP_Party_Entities.find(
            (entity) => entity.Id == this.itemData.Entity_ID
          );
          if (selectedEntity !== undefined)
            this.sectionTitle = selectedEntity.Entity;
        }
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined) {
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          ) {
            this.builder.manageError(
              `Parties_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
              this.dataForm.status !== "VALID",
              this.dataForm.controls
            );
            this.cdr.detectChanges();
          }
        }
      });
    if (this.itemData.First_Business_Name !== undefined)
      this.getNameVariations(this.itemData.First_Business_Name);
    this.builder.deletedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.handleDeletedItems(data));

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName == "itemData") {
        if (this.itemData) {
          this.sectionPlaceholders.initializePlaceholderState(
            this.dataForm,
            this.itemData.placeholder_state
          );
          this.patchDataFormValues(this.itemData)
          if(isAIFilled(this.itemData.placeholder_state)){
            this.isAIEnabled.set(true)
           } 
        }
      }
    }
  }

  patchDataFormValues(itemData) {
    if (!itemData) {
      return;
    }
  
    this.dataForm.patchValue({
      Last_Name: itemData.Last_Name!=='NA' ? itemData.Last_Name || '': "",
      Middle_Name:   itemData.Middle_Name  !== 'NA' ? itemData.Middle_Name || '' : '',
      AKA: itemData.AKA != 'NA' ? itemData.AKA || "" : '',
      First_Business_Name: itemData.First_Business_Name !== 'NA' ? itemData.First_Business_Name || '' : "",
    });
  }

  async handlePAIButtonClick() {
    this.isLoadingDataFromAI = true;
    const sectionInfo = {
      orderId: this.orderDetails.Order_ID,
      reportId: this.itemData.Sp_Id,
      sectionId: this.itemData.Id,
      typeId: this.sectionType,
      entityId : this.itemData.Entity_ID,
      sectionType: 'search_parties'
    };
    this.isAIEnabled.set(true)

    const response = await this.sectionPlaceholders
      .getPartySectionInfoFromAI(sectionInfo, this.itemData)
      .toPromise();
    
    // Save the current alias array before updating
    const currentAliases = this.itemData.Alias ? [...this.itemData.Alias] : [];
    
    this.sectionPlaceholders.updatePlaceholdersFromResponse(response);
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.patchDataFormValues(response);
    const formattedData = this.dataForm.value;
    
    this.itemData = {...this.itemData, ...formattedData, Alias: currentAliases};
    
    if (this.itemData.First_Business_Name !== this.dbData.First_Business_Name) {
      this.builder.getNameSearchVariations(this.itemData.First_Business_Name)
        .subscribe(data => {
          this.searchVariations = data;
        });
    }
    
    this.saveItem(this.dataForm, true);
    this.isLoadingDataFromAI = false;
  }

  onFieldValueChange(newValue: string | moment.Moment, field: string): void {
    this.sectionPlaceholders.updatePlaceholderState(field, {
      value: newValue,
      status: "edited",
    });
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.itemData[field] = newValue ? newValue : null;
  }

  onAcceptance(field: string): void {
    this.sectionPlaceholders.acceptSuggestion(field);
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.saveItem(this.dataForm, true);
  }

  updateDocumentCountCallback(count: number) {
    if (count > 0) {
      this.isDocumentEmpty.set(false);
      return;
    }
    this.isDocumentEmpty.set(true);
  }


  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
  }

  handleDeletedItems(data) {
    if (data) {
      if (data.hasOwnProperty("SP_Parties_Alias"))
        this.deletedAliases = data["SP_Parties_Alias"].filter(
          (ele) => ele.SP_Party_ID == this.itemData.Derived_From
        );
    }
  }

  saveItem(event, forced = false) {
    let refreshAlias = false;
    if (
      this.itemData &&
      this.dbData &&
      this.itemData.First_Business_Name !== this.dbData.First_Business_Name
    ) {    
      this.getNameVariations(this.itemData.First_Business_Name);
      refreshAlias = true;
    }
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `Parties_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine || forced) {
      this.itemData.placeholder_state = this.sectionPlaceholders.getState();
      let payload = {
        curData: this.builder.trimInput(event, this.itemData),
        dbData: this.dbData,
        Sp_Id: this.reportDetails["Id"],
        Derived_From: this.reportDetails["Derived_From"],
      };
      this.builder
        .saveSearchParties(payload, false)
        .pipe(take(1))
        .subscribe(() => {
          if (refreshAlias) {
            setTimeout(() => {
              this.itemData.Alias = this.dbData.Alias;
            }, 0);
          }
        });
    }
  }

  deleteItem() {
    this.matDialog
      .open(ConfirmComponent, {
        data: {
          title: "Delete " + this.sectionTitle,
          message:
            "Are you sure you want to delete this " + this.sectionTitle + " ?",
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `Parties_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder
            .saveSearchParties(payload, true)
            .pipe(take(1))
            .subscribe();
        }
      });
  }

  removeName(item) {
    item.Status = 0;
    this.saveAlias(true);
  }

  addName() {
    this.itemData.Alias.push({
      Id: "New",
      SP_Party_ID: this.itemData.Id,
      Alias: "",
      IsLegal: 0,
      Is_Judgments_Liens: 0,
    });
    this.saveAlias(true);
  }

  onVariationClick(variation, legalLastName) {
    let aliasName = variation;
    if (legalLastName && legalLastName.trim())
      aliasName += " " + legalLastName.trim();
    this.itemData.Alias.push({
      Alias: aliasName,
      Id: "New",
      SP_Party_ID: this.itemData.Id,
      IsLegal: 0,
      Is_Judgments_Liens: 0,
    });
    this.saveAlias(true);
  }

  isAdded(variation) {
    return this.itemData.Alias.some(
      (item) => item && item.Alias && item.Alias.split(" ")[0] === variation
    );
  }

  getNameVariations(name) {
    if (name && name.trim()) {
      if (this.itemData.Alias == undefined) this.itemData.Alias = [];
      let legalIdx = this.itemData.Alias.findIndex((name) => name.IsLegal == 1);
      // if (legalIdx !== -1) this.itemData.Alias[legalIdx].Alias = name;
      // else {
      if (this.itemData.Alias && this.itemData.Alias.length == 0) {
        this.itemData.Alias.push({
          Id: "New",
          SP_Party_ID: this.itemData.Id,
          Alias: "",
          IsLegal: 1,
          Is_Judgments_Liens: 1,
        });
      } else if (
        this.itemData.Alias &&
        this.itemData.Alias.length > 0 &&
        legalIdx == -1
      ) {
        //For Existing data
        this.itemData.Alias.push({
          Id: "New",
          SP_Party_ID: this.itemData.Id,
          Alias: "",
          IsLegal: 1,
          Is_Judgments_Liens: 1,
        });
      }
      // }
      return this.builder.getNameSearchVariations(name).subscribe((data) => {
        this.searchVariations = data;
      });
    } else {
      this.searchVariations = [];
      if (this.itemData.Alias !== undefined) {
        let lglIdx = this.itemData.Alias.findIndex((ele) => ele.IsLegal == 1);
        if (lglIdx !== -1) this.itemData.Alias[lglIdx].Status = 0;
      }
    }
  }

  saveAlias(type) {
    this.itemData.Alias.forEach((item) => (item.Alias = item.Alias.trim()));
    let payload = {
      curData: this.itemData,
      dbData: this.dbData,
      Sp_Id: this.reportDetails["Id"],
      Derived_From: this.reportDetails["Derived_From"],
    };
    this.builder.saveSearchParties(payload, type).pipe(take(1)).subscribe();
  }

  handleLienJudmntsChange(item) {
    item.Probate_Court = 0;
    item.Divorce_Court = 0;
    item.Civil_Court = 0;
    item.Judgements_Found = null;
    item.Liens_Found = null;
    this.saveAlias(false);
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "SRC_PT",
      this.itemData.Id,
      fieldName
    );
  }

  isAliasFieldModified(fieldName, item) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "SRC_PT_ALS",
      item.Id,
      fieldName
    );
  }

  sortedAlias() {
    if (this.itemData.Alias == undefined) return [];
    return this.itemData.Alias.sort((a, b) => b.IsLegal - a.IsLegal);
  }
}
