import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import {AssessmentSectionStateService} from '../assesment-section-state.service'
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { convertToNumber } from "app/utils/number-utils";
import { isAIFilled } from "app/utils/utils";

@Component({
  selector: "app-rb-assessment-section",
  templateUrl: "./rb-assessment-section.component.html",
  styleUrls: ["./rb-assessment-section.component.scss"],
  providers:[AssessmentSectionStateService]
})
export class RbAssessmentSectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() dbData: any;
  @Input() itemData: any;
  @Input() itemIndex: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("firstInput") firstInput: MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionName = "assessment";
  sectionType = "assessments";
  sectionDocs: any = [];
  isToggleChanged = false;
  reportDetails = {
    Derived_From: null,
  };
  orderDetails: any = {};
  isDocumentEmpty = signal<boolean>(true);
  isAIEnabled = signal<boolean>(false);
  isLoadingDataFromAI = false

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService,
    private sectionPlaceholders: AssessmentSectionStateService
  ) {
    this.dataForm = this.frmBuilder.group({
      Year: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(4)]),
      ],
      Land: [null],
      Building: [null],
      Extras: [null],
      Total: [null],
      Comments: [null, [Validators.maxLength(65535)]],
      Internal_Comments: [null, [Validators.maxLength(65535)]],
      Applies: [false],
      Parcel: [null, [Validators.maxLength(255)]],
      Language: ["good", Validators.required],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(this.itemData.Applies == 1, {
      emitEvent: false,
    });
    if (!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName == "itemData" ) {
        if (this.itemData) {
          this.sectionPlaceholders.initializePlaceholderState(
            this.dataForm,
            this.itemData.placeholder_state
          );
         this.patchDataFormValues(this.itemData)
         if(isAIFilled(this.itemData.placeholder_state)){
          this.isAIEnabled.set(true)
         } 
        }
      }
    }
  }

  patchDataFormValues(itemData) {
    const formUpdates = {};
    Object.keys(itemData).forEach((key) => {
      switch (key) {
        case  'Total':
          formUpdates[key] = convertToNumber(itemData[key]);
          break;
        case  'Land':
          formUpdates[key] = convertToNumber(itemData[key]);
          break;
        case  'Extras':
          formUpdates[key] = convertToNumber(itemData[key]);
          break;
        case  'Building':
          formUpdates[key] = convertToNumber(itemData[key]);
          break;
        case 'Language':
          formUpdates[key] = itemData.Language || "good";
          break;
        case 'Applies':
          formUpdates[key] = itemData.Applies || false;
          break;
        case 'Year':
          formUpdates[key] = itemData[key]!=='NA' ? itemData[key] : null;
          break;
        default:
          formUpdates[key] = itemData[key]!=='NA' ? itemData[key] : null;
          break;
      }
    });
    this.dataForm.patchValue({
      ...formUpdates
    });
     
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined) {
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          ) {
            this.builder.manageError(
              `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
              this.dataForm.status !== "VALID",
              this.dataForm.controls
            );
            this.cdr.detectChanges();
            this.manageAppliesTgleChange(
              this.dataForm.controls["Year"],
              [
                Validators.compose([
                  Validators.required,
                  Validators.maxLength(4),
                ]),
              ],
              [Validators.maxLength(4)]
            );
          }
        }
      });
    this.cdr.detectChanges();
    this.setFieldAsMarked("Year");
  }

  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
    this.manageAppliesTgleChange(
      this.dataForm.controls["Year"],
      [Validators.compose([Validators.required, Validators.maxLength(4)])],
      [Validators.maxLength(4)]
    );
    this.isToggleChanged = true;
  }

  manageAppliesTgleChange(control, onValidators, offValidators) {
    if (!this.itemData.Applies) {
      control.setValidators(offValidators);
      control.setErrors(null);
      this.builder.manageError(
        `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        false,
        {}
      );
    } else {
      control.setValidators(onValidators);
      this.builder.manageError(
        `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        this.dataForm.status !== "VALID",
        this.dataForm.controls
      );
    }
    control.updateValueAndValidity();
  }

  async handlePAIButtonClick() {
    this.isLoadingDataFromAI = true
    const sectionInfo = {
      orderId: this.orderDetails.Order_ID,
      reportId: this.itemData.Sp_Id,
      sectionId: this.itemData.Id,
      typeId:  this.sectionType,
      entityId :'0',
      sectionType: 'assessments'
    };
    this.isAIEnabled.set(true)

    const response = await this.sectionPlaceholders
      .getAssesmentInforFromAI(sectionInfo)
    
    this.sectionPlaceholders.updatePlaceholdersFromResponse(response);
    this.updateFormFromAIResponse(response);
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.patchDataFormValues(response)
    const formattedData = this.dataForm.value
    this.itemData = {...this.itemData, ...formattedData}
    this.saveItem(this.dataForm, true);
    this.isLoadingDataFromAI = false
  }

  updateFormFromAIResponse(response) {
    const { Year, Building, Land, Extras, Total, Parcel } = response;
    if (Year && Year.trim() != 'NA') {
      const newValue = Year.trim()
      this.itemData.Year = newValue;
      this.sectionPlaceholders.updatePlaceholderState("Year", {
        value: newValue,
      });
    }
    if (Building) {
      const newValue =
        Building !== "NA" && !isNaN(convertToNumber(Building))
          ? convertToNumber(Building)
          : 0.0;
      this.itemData.Building = newValue;
      this.sectionPlaceholders.updatePlaceholderState("Building", {
        value: newValue,
      });
    }

    if (Land) {
      const newValue =
        Land !== "NA" && !isNaN(convertToNumber(Land)) ? convertToNumber(Land) : 0.0;
      this.itemData.Land = newValue;
      this.sectionPlaceholders.updatePlaceholderState("Land", {
        value: newValue,
      });
    }

    if (Extras) {
      const newValue =
        Extras !== "NA" && !isNaN(convertToNumber(Extras))
          ? convertToNumber(Extras)
          : 0.0;
      this.itemData.Extras = newValue;
      this.sectionPlaceholders.updatePlaceholderState("Extras", {
        value: newValue,
      });
    }

    if (Total) {
      const newValue =
        Total !== "NA" && !isNaN(convertToNumber(Total)) ? convertToNumber(Total) : 0.0;
      this.itemData.Total = newValue;
      this.sectionPlaceholders.updatePlaceholderState("Total", {
        value: newValue,
      });
    }

    if (Parcel && Parcel.trim() !== 'NA' ) {
      const newValue = Parcel.trim()
      this.itemData.Parcel = newValue;
      this.sectionPlaceholders.updatePlaceholderState("Parcel", {
        value: newValue,
      });
    }
  }

  saveItem(event, forced = false) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine || forced) {
      this.itemData.placeholder_state = this.sectionPlaceholders.getState();
      let payload = {
        curData: this.builder.trimInput(event, this.itemData),
        dbData: this.dbData,
        Sp_Id: this.reportDetails["Id"],
        Derived_From: this.reportDetails["Derived_From"],
        toggleControl: {
          changed: this.isToggleChanged,
          state: this.itemData.Applies,
        },
      };
      this.isToggleChanged = false;
      this.builder.saveAssesments(payload, false).pipe(take(1)).subscribe();
    }
  }

  deleteItem() {
    this.matDialog
      .open(ConfirmComponent, {
        data: {
          title: "Delete assessment",
          message: "Are you sure you want to delete this assessment ?",
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `Assessments_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder.saveAssesments(payload, true).pipe(take(1)).subscribe();
        }
      });
  }

  handleCommitmentError(isValid) {
    this.dataForm.controls["Language"].setValue(isValid ? "good" : null);
  }

  onFieldValueChange(newValue: string, field: string): void {
    this.sectionPlaceholders.updatePlaceholderState(field, {
      value: newValue,
      status: "edited",
    });
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.itemData[field] = newValue ? newValue : null;
  }

  onAcceptance(field: string): void {
    this.sectionPlaceholders.acceptSuggestion(field);
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.saveItem(this.dataForm, true);
  }

  updateDocumentCountCallback(count: number) {
    if (count > 0) {
      this.isDocumentEmpty.set(false)
      return;
    }
    this.isDocumentEmpty.set(true)
  }

  setFieldAsMarked(fieldKey) {
    var keyIndex = Object.keys(this.dataForm.controls).findIndex(
      (key) => key == fieldKey
    );
    if (keyIndex > -1) {
      let key = Object.keys(this.dataForm.controls)[keyIndex];
      this.dataForm.get(key).markAsTouched();
    }
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "ASS_NS",
      this.itemData.Id,
      fieldName
    );
  }
}
