import { Injectable } from '@angular/core';
import { BaseAiSectionsService, SectionInfo } from '../base-ai-sections.service';
import { Observable } from 'rxjs';


export class SecurityInstrumentsSectionService extends BaseAiSectionsService{

  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    const attributes = {
       Borrower: '',
            Trustee: '',
            Rider: '',
            Lender: '',
            Book: '',
            Page: '',
            Amount: '',
            Dated_Date:'',
            Rec_Date:'',
            Maturity_Date:'',
            Assignments: '',
            Instrument_Name:'',
            Language: '',
    }
    return this.reportBuilder.getSectionDataFromAI(attributes, sectionInfo);
  }
 
}
