import { Injectable } from '@angular/core';
import { BaseAiSectionsService, SectionInfo } from '../base-ai-sections.service';
import { Observable } from 'rxjs';

export class DeedSectionStateService extends BaseAiSectionsService{

  attributes = {}

  async getDeedSectionInfoFromAI(sectionInfo:SectionInfo, instrumentTypeOptions): Promise<any> {
    const names = instrumentTypeOptions.map(e=>e.Name)
     this.attributes = {
      Grantor: '',
      Instrument_Type_ID: `select any one value from this list :- ${JSON.stringify(names)}`,
      Grantee: '',
      Estate_Of: '',
      Beneficiaries: '',
      Instrument_Num: '',
      Page: '',
      Dated_Date: '',
      Rec_Date: '',
      Date_Of_Death: '',
      Consideration: '',
     }
     const response = await this.getSectionInfoFromAI(sectionInfo).toPromise()
     const selectedOption = instrumentTypeOptions.find(
      (e) => e.Name.toLowerCase() === response.Instrument_Type_ID.toLowerCase()
    );
    return {...response, Instrument_Type_ID: selectedOption?.Id ?? null}
  }

  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    return this.reportBuilder.getSectionDataFromAI(this.attributes, sectionInfo); 
  }
}
