import { Component, OnInit ,Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'underscore';

@Component({
  selector: 'app-mat-select-filter',
  templateUrl: './mat-select-filter.component.html',
  styleUrls: ['./mat-select-filter.component.scss']
})
export class MatSelectFilterComponent implements OnInit, OnChanges {

  searchKeyType: string;
  selectedOption:any;
  timer: any = [];
  selectedEntityList :Array<any>;
  @Input() entityList : Array<any>; 
  @Input() searchKey : any;
  @Input() element_Id:any 
  @Input() element_Name:any 
  @Input() hideCommitId=false;
  @Input() isSelectAllEnabled : boolean;
  @Input() placeholder : any;
  @Input() selectKey : any;
  @Input() selected: any;  
  @Input() disabled: boolean = false;
  @Input() selectAllValue: any = 'all'; 
  @Input() isLoading: boolean = false; 
  @Input() element_From_Control:UntypedFormControl; 
  @Output() selectedChange = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() optionChange = new EventEmitter<any>();
  @ViewChild('myInput')  myInput :ElementRef
  @Input() isFieldModified : boolean = false;
  constructor() { }  

  ngOnInit() {
    if(_.isArray(this.searchKey)) this.searchKeyType = 'array';
    else if(_.isString(this.searchKey)) this.searchKeyType = 'string';
    else this.searchKeyType = 'any';
    if(this.selected && this.selected.Cust_Commit_Type_Id){
      this.selectedOption = this.selected.Cust_Commit_Type_Id
    }else{
      this.selectedOption = this.selected
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for(let prop in changes){
      if(prop == 'entityList'){        
        this.selectedEntityList = this.entityList;
      }
      if(prop == 'selected'){        
        this.selectedOption = this.selected;
      }      
    }
  }

  searchOptions(query){
    if(query.keyCode == 32 && query.type == "keydown") query.stopPropagation();
    let result = this.select(query.target.value);
    this.selectedEntityList = result;
  }

  select(query: string):string[]{
    let result: string[] = [];
    if(_.isArray(this.searchKey)){
      for(let a of this.entityList){
        var matched = null;
        for(let key of this.searchKey){
          if(a[key] && a[key].toLowerCase().indexOf(query.toLowerCase().trim()) > -1){
            if((matched == null) || (matched && matched[this.selectKey] != a[this.selectKey])) matched = a;
          }
        }  
        if(matched) result.push(matched);        
      }
    }
    else if(_.isString(this.searchKey)){
      for(let a of this.entityList){
        if(a[this.searchKey].toLowerCase().indexOf(query.toLowerCase().trim()) > -1){
          result.push(a)
        }
      }
    }
    else console.log("Invalid search key");
    return result;
  }

  emitSelected(){
    this.selectedChange.emit(this.selectedOption);
    this.selectionChange.emit(this.selectedOption);
  }

  clearSearch(){
    this.myInput.nativeElement.value = '';
    this.selectedEntityList = this.entityList;
  } 

  handlePanelEvents(event){    
    if(event) this.timer.push(setTimeout(() => { this.myInput.nativeElement.focus() }, 0));
    else this.clearSearch();
  }

  selectionChangeEvents(event){
    this.optionChange.emit(this.selectedOption);
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

}
