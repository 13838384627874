import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { CONSTANTS } from "app/app.constants";
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { RbSecInstSectionComponent } from "./rb-sec-inst-section/rb-sec-inst-section.component";

@Component({
  selector: 'app-rb-sec-inst',
  templateUrl: './rb-sec-inst.component.html',
  styleUrls: ['./rb-sec-inst.component.scss']
})
export class RbSecInstComponent implements OnInit, OnDestroy {
  @ViewChildren('section') sections!: QueryList<RbSecInstSectionComponent>;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionObject: any = {};
  basicSectionObject: any = {};
  searchPackage: any = {};
  sectionOptions: any;
  sectionData: any = [];
  deletedSectionData: any = [];
  dbSectionData: any = [];
  generalComments = null;
  isDragable: boolean = true;
  constants= CONSTANTS;
  searchString: string;
  searchCtrl:FormControl = new FormControl();
  filteredSectionOptions:any;
  @ViewChild('itemSearchInp') itemSearchInp : ElementRef
  constructor(private builder: RptBuilderService,private frmBuilder: UntypedFormBuilder) {
    this.dataForm = this.frmBuilder.group({
      SI_Manual_Sort: [false],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveToDb(this.dataForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) =>
          (this.sectionOptions = data ? data.SP_Security_Instruments_Entities : null)
      );
      this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData) {
          this.basicSectionObject = data;
          this.searchPackage = data.dbData;
          this.dataForm.controls["SI_Manual_Sort"].patchValue(
            this.searchPackage.SI_Manual_Sort == 1,
            { emitEvent: false }
          );
        }
      });
    this.builder.secInst$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.generalComments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComments(data));
    this.builder.deletedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initDeletedItems(data));
    this.searchCtrl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => {
        this.filterItems(value)
      });
  }

  filterItems(search){
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search ? search.trim(): "";
    if (!format.test(search) && search == " ") search = "";
    this.filteredSectionOptions = this.sectionOptions;
    search = search.toLowerCase();
    if (search !== "")
      this.filteredSectionOptions = this.sectionOptions.filter(
        (itm) =>
          (itm.Entity && itm.Entity.toLowerCase().includes(search)) ||
          (itm.Name && itm.Name.toLowerCase().includes(search)) 
      );
    if(this.itemSearchInp)this.itemSearchInp.nativeElement.focus();
  }

  menuOpened(){    
    this.searchString = "";
    this.filterItems("");
    setTimeout(()=> {
      if(this.itemSearchInp) this.itemSearchInp.nativeElement.focus();
    }, 0)
  }

  initComments(data) {
    if (data && data.curData) {
      let comment = data.curData.find(
        (gc) =>
          gc.Comments_Type_ID == CONSTANTS.srchPkgItmTypes.secInsts
      );
      this.generalComments = comment !== undefined ? comment : null;
    }
  }

  initComponent(data) {
    this.sectionObject = data;
    //modifiying thecondtion below because adding new assessments is not refrshing the list
    // if (data && data.curData && this.getLength() == 0) {
    if (data && data.curData && data.dbData) {
      this.sectionData =  data.curData;
      this.dbSectionData =  data.dbData;
    } else {
      this.sectionData = [];
      this.dbSectionData = [];
    }
  }

  initDeletedItems(data) {    
    if(data){
      if(data.hasOwnProperty('SP_Security_Instruments')) this.deletedSectionData = data['SP_Security_Instruments'];
    }
  }

  getLength() {
    return this.sectionData ? this.sectionData.length : 0;
  }

  removeGenMessage() {
    let copyGenCom = {...this.generalComments}
    this.generalComments.Status = 0;
    this.builder.updateGeneralComment(this.generalComments, copyGenCom, this.searchPackage.Id, true).subscribe();
  }

  addGenMessage() {
    if (this.generalComments) this.generalComments.Status = 1;
    else {
      this.generalComments = {
        Id: "New",
        Sp_Id: this.searchPackage.Id,
        Comments: "",
        Comments_Type_ID: CONSTANTS.srchPkgItmTypes.secInsts,
      };
    }
    this.builder.updateGeneralComment(this.generalComments, null, this.searchPackage.Id, true).subscribe();
  }

  addNewSectionItem(item, sortOrder) {
    let secIns = {
      Id: "New",
      Sp_Id: this.searchPackage.Id,
      Entity_ID: item.Id,
      Borrower: "",
      Trustee: "",
      Lender: "",
      Amount: 0,
      Instrument_Name: item.Name,
      Book: null,
      Page: null,
      Dated_Date: null,
      Rec_Date: null,
      Maturity_Date: null,
      Rider: null,
      Assignments: null,
      Comments: "",
      Sort_Order: sortOrder,
      Applies: 1,
      Derived_From: null,
      Status: 1,
    };
    let payload = {
      curData: secIns,
      dbData: null,
      Sp_Id: this.searchPackage['Id'],
      Derived_From: this.searchPackage['Derived_From']
    }
    this.builder
      .saveInstrument(payload, true)
      .pipe(take(1))
      .subscribe(() => { setTimeout(() => {
        const index = this.sectionData.findIndex(e=> e.Sort_Order === sortOrder)
        this.focusAddedItem(index);
      }),0});
  }

  isDragEnable(val) {
    if (val) {
      this.isDragable = false;
    } else {
      this.isDragable = true;
    }
  }

  getdbData(secIns) {
    let dbData = this.dbSectionData.find(
      (item) => item.Id == secIns.Id
    );
    if (dbData !== undefined) return dbData;
    return null;
  }

  dropSectionBlock(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.sectionData,
      event.previousIndex,
      event.currentIndex
    );
    this.sectionData.forEach((item, index) => {
      item.Sort_Order = index;
    });
    let payload = {
      curData: this.sectionData,
      dbData: this.dbSectionData,
      Sp_Id: this.searchPackage['Id'],
      Derived_From: this.searchPackage['Derived_From']
    }
    this.builder.updateInstrumentSortOrder(payload).subscribe();
  }

  saveToDb(event) {
    if (!event.pristine) {
      this.basicSectionObject.curData = this.searchPackage;
      this.basicSectionObject.Sp_Id = this.searchPackage.Id;
      this.basicSectionObject.Derived_From = this.searchPackage.Derived_From;
      this.builder.saveBasicData(this.basicSectionObject).pipe(take(1)).subscribe();
    }
  }

  updateSecSort(data) {
    this.searchPackage.SI_Manual_Sort = data.checked ? 1 : 0;
  }

  focusAddedItem(index){
    if(this.sections && this.sections.length > 0){
      if(this.sections.get(index).firstInput) this.sections.get(index).firstInput.focus()
    }
  }

  focusFirsttItem(){
    if(this.sections && this.sections.length > 0){
      if(this.sections.first['firstInput']) this.sections.first['firstInput'].focus()
    }
  }
  
}
