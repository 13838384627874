import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import {VestingSectionService} from '../vesting-section.service'
import { convertToNumber } from "app/utils/number-utils";
const isValidDate = (dateStr) => dateStr && dateStr!=='NA' ? moment(dateStr, "MM/DD/YYYY").isValid() : false;

@Component({
  selector: "app-rb-vesting-section",
  templateUrl: "./rb-vesting-section.component.html",
  styleUrls: ["./rb-vesting-section.component.scss"],
  providers:[VestingSectionService]
})
export class RbVestingSectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() dbData: any;
  @Input() itemData: any;
  @Input() itemIndex: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("firstInput") firstInput: MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionName = "Vesting Deed";
  sectionType = "Vesting Deed";
  sectionDocs: any = [];
  sectionTitle: any = "";
  isToggleChanged = false;
  reportDetails = {
    Derived_From: null,
  };
  orderDetails: any = {};
  instrumentOptions: any = [];
  getUSDate = new Date("01/02/1500").toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  min_Date = new Date(this.getUSDate);
  today = new Date();
  isDocumentEmpty = signal<boolean>(true);
  isAiEnabled = signal<boolean>(false);
  instrumentTypeOptions = [];

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService,
    private chnageDetRef: ChangeDetectorRef,
    private sectionPlaceholders: VestingSectionService
  ) {
    this.dataForm = this.frmBuilder.group({
      Grantor: [null, [Validators.maxLength(2048)]],
      Instrument_Type_ID: [null],
      Grantee: [null, [Validators.maxLength(2048)]],
      Estate_Of: [null, [Validators.maxLength(2048)]],
      Beneficiaries: [null, [Validators.maxLength(2048)]],
      Instrument_Num: [null, [Validators.maxLength(127)]],
      Page: [null, [Validators.maxLength(127)]],
      Dated_Date: [{ value: "", disable: false }],
      Rec_Date: [{ value: "", disable: false }],
      Comments: [null, [Validators.maxLength(65535)]],
      Internal_Comments: [null, [Validators.maxLength(65535)]],
      Date_Of_Death: [{ value: "", disable: false }],
      Consideration: [null],
      Others: [null, [Validators.maxLength(65535)]],
      Applies: [null],
      Language: ["good", Validators.required],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(this.itemData.Applies == 1, {
      emitEvent: false,
    });
    if (!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          let selectedEntity = data.SP_Chain_Of_Title_Entities.find(
            (entity) => entity.Id == this.itemData.Entity_ID
          );
          if (selectedEntity !== undefined)
            this.sectionTitle = selectedEntity.Entity;
        }
        this.instrumentOptions = data.SP_Chain_Of_Title_Instrument_Types;
        this.computeInformationTypeOptions()
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined) {
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          ) {
            this.builder.manageError(
              `Vesting_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
              this.dataForm.status !== "VALID",
              this.dataForm.controls
            );
            this.cdr.detectChanges();
          }
        }
      });
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName == "itemData" ) {
        if (this.itemData) {
          this.sectionPlaceholders.initializePlaceholderState(
            this.dataForm,
            this.itemData.placeholder_state
          );
          this.computeInformationTypeOptions();
          this.patchDataFormValues(this.itemData)
          if(this.itemData.placeholder_state) {
            this.isAiEnabled.set(true)
          }
        }
      }
    }
  }

  patchDataFormValues(itemData) {
    if (!itemData) {
      return;
    }
    const formUpdates = {};
  
    Object.keys(itemData).forEach((key) => {
      switch (key) {
        case 'Grantor':
        case 'Grantee':
        case 'Estate_Of':
        case 'Beneficiaries':
          formUpdates[key] = itemData[key] && itemData[key]!=='NA' ? String(itemData[key]).slice(0, 2048) : null;
          break;
        case 'Instrument_Num':
        case 'Page':
          formUpdates[key] = itemData[key] && itemData[key]!=='NA' ? String(itemData[key]).slice(0, 127) : null;
          break;
        case 'Comments':
        case 'Internal_Comments':
        case 'Others':
          formUpdates[key] = itemData[key] && itemData[key]!=='NA' ? String(itemData[key]).slice(0, 65535) : null;
          break;
        case 'Dated_Date':
        case 'Rec_Date':
        case 'Date_Of_Death':
          formUpdates[key] = isValidDate(itemData[key]) ? itemData[key] : null;
          break;
        case 'Consideration':
          formUpdates[key] = convertToNumber(itemData[key]);
          break;
        case 'Applies':
          formUpdates[key] = itemData[key] ?? false;  
          break;
        case 'Language':
          formUpdates[key] = itemData[key] || "good";
          break;
        case 'Instrument_Type_ID':
            formUpdates[key] = itemData[key];
            break;
        default:
          formUpdates[key] = itemData[key] && itemData[key]!=='NA' ? itemData[key] : null;
          break;
      }
    });
    this.dataForm.patchValue(formUpdates);
  }
  

  computeInformationTypeOptions() {
    let options = []
    if (
      this.itemData.Entity_ID == 1 ||
      this.itemData.Entity_ID == 3 ||
      this.itemData.Entity_ID == 4
    ) {
      options = this.getInsTypesForDeed();
    }
    if (this.itemData.Entity_ID === 2) {
      options = this.getInsTypesForEstate();
    }
    this.instrumentTypeOptions = options.map((e)=> ({...e, Name:e.Type}))
  }

  async handlePAIButtonClick() {
    const orderId = this.orderDetails.Order_ID;
    const reportId = this.itemData.Sp_Id;
    const typeId = this.sectionType;
    const sectionId = this.itemData.Id;

    const sectionInfo = {
      orderId: orderId,
      reportId: reportId,
      sectionId: sectionId,
      typeId: typeId,
    };
    this.isAiEnabled.set(true)
    const response = await this.sectionPlaceholders
      .getVestingSectionInfoFromAI(sectionInfo, this.instrumentTypeOptions);
    this.sectionPlaceholders.updatePlaceholdersFromResponse(response);
    this.updateFormFromAIResponse(response);
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.patchDataFormValues(response)
    const formattedData = this.dataForm.value
    this.itemData = {...this.itemData, ...formattedData}
    this.saveItem(this.dataForm, true);
    console.log(this.itemData);
  }


  updateFormFromAIResponse(response) {
    // check for valid date
    if(response.Dated_Date){
      const isValid = isValidDate(response.Dated_Date)
      if(isValid){
        this.sectionPlaceholders.updatePlaceholderState(
          "Dated_Date",
          { value: response.Dated_Date, status: "autofilled" }
        );
        this.itemData.Dated_Date = response.Dated_Date
      }
      else{
        this.sectionPlaceholders.updatePlaceholderState(
          "Dated_Date",
          { value: null, status: "unsure" }
        );
      }
    }
    if(response.Rec_Date){
      const isValid = isValidDate(response.Rec_Date)
      if(isValid){
        this.sectionPlaceholders.updatePlaceholderState(
          "Rec_Date",
          { value: response.Rec_Date, status: "autofilled" }
        );
        this.itemData.Rec_Date = response.Rec_Date
      }
      else{
        this.sectionPlaceholders.updatePlaceholderState(
          "Rec_Date",
          { value: null, status: "unsure" }
        );
      }
    }
    if(response.Date_Of_Death){
      const isValid = isValidDate(response.Date_Of_Death)
      if(isValid){
        this.sectionPlaceholders.updatePlaceholderState(
          "Date_Of_Death",
          { value: response.Date_Of_Death, status: "autofilled" }
        );
        this.itemData.Date_Of_Death = response.Date_Of_Death
      }
      else{
        this.sectionPlaceholders.updatePlaceholderState(
          "Date_Of_Death",
          { value: null, status: "unsure" }
        );
      }
    }
  }

  validateStatus(input: string): "autofilled" | "default" | "edited" | "accepted"  {
    if (input === "autofilled" || input === "default" || 
        input === "edited" || input === "accepted") {
      return input;
    }
    return "default"; // fallback value
  };

  onFieldValueChange(newValue: string|moment.Moment, field: string, status='edited'): void {
    this.sectionPlaceholders.updatePlaceholderState(field, {
      value: newValue,
      status: this.validateStatus(status),
    });
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.itemData[field] = newValue ? newValue : null;
  }

  onAcceptance(field: string): void {
    this.sectionPlaceholders.acceptSuggestion(field);
    this.itemData.placeholder_state = this.sectionPlaceholders.getState();
    this.saveItem(this.dataForm, true);
  }

  updateDocumentCountCallback(count: number) {
    if (count > 0) {
      this.isDocumentEmpty.set(false);
      return;
    }
    this.isDocumentEmpty.set(true);
  }

  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
    this.isToggleChanged = true;
  }

  saveItem(event, forced = false) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `Vesting_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine || forced) {
      this.itemData.placeholder_state = this.sectionPlaceholders.getState();
      let payload = {
        curData: this.builder.trimInput(event, this.itemData),
        dbData: this.dbData,
        Sp_Id: this.reportDetails["Id"],
        Derived_From: this.reportDetails["Derived_From"],
        toggleControl: {
          changed: this.isToggleChanged,
          state: this.itemData.Applies,
        },
      };
      this.isToggleChanged = false;
      this.builder.saveVesting(payload, false).pipe(take(1)).subscribe();
    }
  }

  deleteItem() {
    this.matDialog
      .open(ConfirmComponent, {
        data: {
          title: `Delete ${this.sectionTitle}`,
          message: `Are you sure you want to delete this ${this.sectionTitle.toLowerCase()} ?`,
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `Vesting_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder.saveVesting(payload, true).pipe(take(1)).subscribe();
        } else {
          console.log("Closed", res);
        }
      });
  }

  handleCommitmentError(isValid) {
    this.dataForm.controls["Language"].setValue(isValid ? "good" : null);
  }

  onInsTypehange() {
    this.dataForm.controls["Others"].reset();
  }

  getInsTypesForDeed() {
    return this.instrumentOptions.filter(
      (option) => option.Type_ID == 1 || option.Type_ID == 99
    );
  }

  getInsTypesForEstate() {
    return this.instrumentOptions.filter(
      (option) => option.Type_ID == 2 || option.Type_ID == 99
    );
  }

  getPippinDateDeed(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Dated_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Dated_Date"] = null;
    }
  }
  getPippinDateOD(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Date_Of_Death"] = event.Pippin_Date;
      this.itemData["Dated_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Date_Of_Death"] = null;
    }
  }
  getPippinDateRec(event) {
    if (event && event.Pippin_Date) {
      this.itemData["Rec_Date"] = event.Pippin_Date;
    } else {
      this.itemData["Rec_Date"] = null;
    }
  }

  checkForETAValidation() {
    if (this.dataForm) {
      if (!this.dataForm.valid && !this.dataForm.touched) return true;
      else false;
    } else false;
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "VES_DD",
      this.itemData.Id,
      fieldName
    );
  }
}
