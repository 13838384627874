import { Component, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-ai-dropdown-field',
  imports: [],
  templateUrl: './ai-dropdown-field.component.html',
  styleUrl: './ai-dropdown-field.component.scss'
})
export class AiDropdownFieldComponent {
 //INPUTS

  //ui attributes
  @Input() label:string;

  //data attributes
  @Input() value:string;
  @Input() status:string;
  @Input() formControl: FormControl;
  @Input() options:any[];
  @Input() field:string;
  @Input() modified: boolean = false;
  @Input() maxLength:string=null;
  @Input() errorMessages: { [key: string]: string } = {};

    //call backs
    @Input()  onValueChange!: (newValue:string,field:string) => Promise<void>;
    @Input()  onStatusChange!: (field:string) => Promise<void>;

 

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((newValue) => {
      if(this.formControl.dirty){
      this.onChange(newValue);
      }
    });
  }

  async onChange(newValue){
    if(!this.onValueChange){
      console.error('Callback function is not provided')
    }
    try {
      await this.onValueChange(newValue,this.field);
    } catch (error) {
      console.error(error)
    } 
  }

  async onButtonClick(){
    if(!this.onStatusChange){
      console.error('Callback function is not provided')
    }
    try {
        await this.onStatusChange(this.field);
    } catch (error) {
      console.error(error)
    } 
  }

  
}
