import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";
import { RptBuilderService } from "app/report-builder/rpt-builder.service";
import { ConfigService } from "app/services";
import { DialogService } from "ng2-bootstrap-modal";
import { Subject } from "rxjs";
import { SecurityInstrumentsSectionService } from '../security-instruments-section.service'
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import * as moment from 'moment';
import { convertToNumber } from "app/utils/number-utils";

@Component({
  selector: 'app-rb-sec-inst-section',
  templateUrl: './rb-sec-inst-section.component.html',
  styleUrls: ['./rb-sec-inst-section.component.scss'],
  providers:[SecurityInstrumentsSectionService]
})
export class RbSecInstSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() dbData: any;
  @Input() itemData: any;
  @Input() itemIndex: any;
  @Output() dragEnable: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('firstInput') firstInput : MatButton;
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionName = "Security Instrument";
  sectionType = "Security Instruments";
  sectionDocs: any = [];
  sectionTitle: any = "";
  isToggleChanged=false;
  reportDetails = {
    Derived_From: null
  };
  orderDetails: any = {};
  getUSDate = new Date("01/02/1500").toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  min_Date = new Date(this.getUSDate);
  isDisabledAI = signal<boolean>(true);

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private matDialog:MatDialog,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private builder: RptBuilderService,
    private chnageDetRef: ChangeDetectorRef,
    private sectionPlaceholders: SecurityInstrumentsSectionService
  ) {
    this.dataForm = this.frmBuilder.group({
      Borrower: [null, Validators.maxLength(2048)],
      Trustee: [null, Validators.maxLength(2048)],
      Rider: [null, Validators.maxLength(2048)],
      Lender: [null, Validators.maxLength(2048)],
      Book: [null, Validators.maxLength(127)],
      Page: [null, Validators.maxLength(127)],
      Amount: [null],
      Dated_Date: [{value:'',disable:false}],
      Rec_Date: [{value:'',disable:false}],
      Maturity_Date: [{value:'',disable:false}],
      Comments: [null, Validators.maxLength(65535)],
      Internal_Comments: [null, [Validators.maxLength(65535)]],
      Assignments: [null, Validators.maxLength(65535)],
      Applies: [null],
      Instrument_Name:[null, Validators.maxLength(512)],
      Language: ["good", Validators.required],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngAfterViewInit(): void {
    this.dataForm.controls["Applies"].patchValue(
      this.itemData.Applies == 1,
      {
        emitEvent: false,
      }
    );
    if(!this.itemData.Status) this.dataForm.disable();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          let selectedEntity = data.SP_Security_Instruments_Entities.find(
            (entity) => entity.Id == this.itemData.Entity_ID
          );
          if (selectedEntity !== undefined)
            this.sectionTitle = selectedEntity.Entity;
        }
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData && data.dbData.Id !== undefined){
          this.reportDetails = data.dbData;
          if (
            this.orderDetails &&
            this.orderDetails.Order_ID !== undefined &&
            this.orderDetails.Order_ID !== ""
          )
           { 
              this.builder.manageError(
                `SecIns_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
                this.dataForm.status !== "VALID",
                this.dataForm.controls
              );
            this.cdr.detectChanges();
          }
        }
      });
      this.sectionPlaceholders.initializePlaceholderState(
        this.dataForm,
        this.itemData.placeholder_state
      );
    this.cdr.detectChanges();
  }

   ngOnChanges(changes: SimpleChanges) {
      for (const propName in changes) {
        if (propName == "itemData") {
          if (this.itemData) {
            this.sectionPlaceholders.initializePlaceholderState(
              this.dataForm,
              this.itemData.placeholder_state
            );
            this.patchDataFormValues(this.itemData)
          }
        }
      }
    }

    patchDataFormValues(itemData) {
      const formUpdates = {};
    
      Object.keys(itemData).forEach((key) => {
        switch (key) {
          case 'Amount':
            formUpdates[key] = convertToNumber(itemData[key]);
            break;
          case 'Language':
            formUpdates[key] = itemData.Language || "good";
            break;
          case 'Applies':
            formUpdates[key] = itemData.Applies || false;
            break;
          default:
            formUpdates[key] = itemData[key];
            break;
        }
      });
    
      this.dataForm.patchValue({
        ...formUpdates
      });
    }
    

    async handlePAIButtonClick() {
      const orderId = this.orderDetails.Order_ID;
      const reportId = this.itemData.Sp_Id;
      const typeId = this.sectionType;
      const sectionId = this.itemData.Id;
  
      const sectionInfo = {
        orderId: orderId,
        reportId: reportId,
        sectionId: sectionId,
        typeId: typeId,
      };
  
      const response = await this.sectionPlaceholders
        .getSectionInfoFromAI(sectionInfo, )
        .toPromise();
      this.sectionPlaceholders.updatePlaceholdersFromResponse(response);
      this.updateFormFromAIResponse(response);
      this.itemData.placeholder_state = this.sectionPlaceholders.getState();
      this.patchDataFormValues(response)
      const formattedData = this.dataForm.value
      this.itemData = {...this.itemData, ...formattedData}
      this.saveItem(this.dataForm, true);
      console.log(this.itemData);
    }

       updateFormFromAIResponse(response) {
         // check for valid date
         const isValidDate = (dateStr) => moment(dateStr, "MM/DD/YYYY").isValid(); 
         if(response.Maturity_Date){
           const isValid = isValidDate(response.Maturity_Date)
           if(isValid){
             this.sectionPlaceholders.updatePlaceholderState(
               "Maturity_Date",
               { value: response.Maturity_Date, status: "autofilled" }
             );
             this.itemData.Date_Date = response.Maturity_Date
           }
           else{
             this.sectionPlaceholders.updatePlaceholderState(
               "Maturity_Date",
               { value: null, status: "edited" }
             );
           }
         }
         if(response.Dated_Date){
          const isValid = isValidDate(response.Dated_Date)
          if(isValid){
            this.sectionPlaceholders.updatePlaceholderState(
              "Dated_Date",
              { value: response.Dated_Date, status: "autofilled" }
            );
            this.itemData.Date_Date = response.Dated_Date
          }
          else{
            this.sectionPlaceholders.updatePlaceholderState(
              "Dated_Date",
              { value: null, status: "edited" }
            );
          }
        }
         if(response.Rec_Date){
           const isValid = isValidDate(response.Rec_Date)
           if(isValid){
             this.sectionPlaceholders.updatePlaceholderState(
               "Rec_Date",
               { value: response.Rec_Date, status: "autofilled" }
             );
             this.itemData.Rec_Date = response.Rec_Date
           }
           else{
             this.sectionPlaceholders.updatePlaceholderState(
               "Rec_Date",
               { value: null, status: "edited" }
             );
           }
         }
         if(response.Date_Of_Death){
           const isValid = isValidDate(response.Date_Of_Death)
           if(isValid){
             this.sectionPlaceholders.updatePlaceholderState(
               "Date_Of_Death",
               { value: response.Date_Of_Death, status: "autofilled" }
             );
             this.itemData.Date_Of_Death = response.Date_Of_Death
           }
           else{
             this.sectionPlaceholders.updatePlaceholderState(
               "Date_Of_Death",
               { value: null, status: "edited" }
             );
           }
         }
       }
     
       validateStatus(input: string): "autofilled" | "default" | "edited" | "accepted"  {
         if (input === "autofilled" || input === "default" || 
             input === "edited" || input === "accepted") {
           return input;
         }
         return "default"; // fallback value
       };
     
       onFieldValueChange(newValue: string|moment.Moment, field: string, status='edited'): void {
         this.sectionPlaceholders.updatePlaceholderState(field, {
           value: newValue,
           status: this.validateStatus(status),
         });
         this.itemData.placeholder_state = this.sectionPlaceholders.getState();
         this.itemData[field] = newValue ? newValue : null;
       }
     
    
      onAcceptance(field: string): void {
        this.sectionPlaceholders.acceptSuggestion(field);
        this.itemData.placeholder_state = this.sectionPlaceholders.getState();
        this.saveItem(this.dataForm, true);
      }


  updateDocumentCountCallback(count: number) {
    if (count > 0) {
      this.isDisabledAI.set(false);
      return;
    }
    this.isDisabledAI.set(true);
  }



  enableDrag(event) {
    this.dragEnable.emit(true);
  }

  diasbleDrag() {
    this.dragEnable.emit(false);
  }

  toggleApplies(event) {
    this.itemData.Applies = event ? 1 : 0;
    this.isToggleChanged=true;
  }

  saveItem(event, forced=false) {
    if (
      this.orderDetails &&
      this.orderDetails.Order_ID !== undefined &&
      this.itemData.Status
    )
      this.builder.manageError(
        `SecIns_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
        event.status !== "VALID",
        event.controls
      );
    if (!event.pristine || forced) {
      let payload = {
        curData: this.builder.trimInput(event, this.itemData),
        dbData: this.dbData,
        Sp_Id: this.reportDetails["Id"],
        Derived_From: this.reportDetails["Derived_From"],
        toggleControl:{changed:this.isToggleChanged,state:this.itemData.Applies}
      };
      this.isToggleChanged=false
      this.builder.saveInstrument(payload, false).pipe(take(1)).subscribe();
    }
  }

  deleteItem() {
    this.matDialog
      .open(
        ConfirmComponent,
        {data:{
          title: `Delete ${this.sectionTitle}`,
          message: `Are you sure you want to delete this ${this.sectionTitle.toLowerCase()} ?`,
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.itemData.Status = 0;
          let payload = {
            curData: this.itemData,
            dbData: this.dbData,
            Sp_Id: this.reportDetails["Id"],
            Derived_From: this.reportDetails["Derived_From"],
          };
          this.builder.manageError(
            `SecIns_${this.orderDetails.Order_ID}_${this.itemData.Id}`,
            false,
            {}
          );
          this.builder.saveInstrument(payload, true).pipe(take(1)).subscribe();
        } else {
          console.log("Closed", res);
        }
      });
  }

  handleCommitmentError(isValid) {
    this.dataForm.controls["Language"].setValue(isValid ? "good" : null);
  }  

  getPippinDateMaturity(event) {
    if(event && event.Pippin_Date ) {
      this.itemData['Maturity_Date'] = event.Pippin_Date;
    } else {
      this.itemData['Maturity_Date'] = null;
    }
  }
  getPippinDateMortgage(event) {
    if(event && event.Pippin_Date ) {
      this.itemData['Dated_Date'] = event.Pippin_Date;
    } else {
      this.itemData['Dated_Date'] = null;
    }
  }
  getPippinDateMortgageRec(event) {
    if(event && event.Pippin_Date ) {
      this.itemData['Rec_Date'] = event.Pippin_Date;
    } else {
      this.itemData['Rec_Date'] = null;
    }
  }

  isFieldModified(fieldName) {
    return this.builder.isFieldModified(
      this.reportDetails["Id"],
      "SCR_IN",
      this.itemData.Id,
      fieldName
    );
  }
}
