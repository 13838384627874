// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  basePath: 'https://serverdev.pippintitle.com',
  basePathSitusAMCService: 'https://situsamc-dev.pippintitle.com',
  access_token: 'xWeh4H0QgqIfM4k5cGW7XQQdIxLXxf9k',
  clientHome:'https://appdev.pippintitle.com',
  adminHome:  'https://appadmindev.pippintitle.com',
  clientBasePath: 'https://serverdev.pippintitle.com',
  qualiaOrgId: '8c36c10f-f58e-4b00-8c92-7373295c3d32',
  superAdminId: '4c96fd2b-ba23-4451-b1be-f55fac496e87',
  salesMarketingGrpId : '13',
  newClientTagId:'130',
  priorityClientTagId: '104',
  firstOrderTagID: "118",
  prePaidTagId:'116',
  partiallyDelivered: '113',
  absDoubleAssign: '127',
  commitmentTypingTagID: '151',
  QCTagId: 41,
  SearchingTagId: 40,
  SoftProTagId: 199,
  AwaitDlvryTagId: 26,
  AwaitPartDlvryTagId: 200,
  vapidPublicKey :"BG72HPTS_Ji63weU-IoYHz62b6A_fpuuQ124rESQqMBl4kX-pupOoROKxJDznNLfOHqKaOauZJZhhaoHzuTwgfI",
  socketBasePath:'https://serverdev.pippintitle.com',
  aws:{
    apiVersion:'2006-03-01',
    region:'us-west-2',
    bucket:'dev.fs.pippintitle',
    credentials:'us-west-2:1a57f7a1-ea10-4f25-86a8-295c8464d9fd'
  },
  nameSearchProductId:1038,
  updateSearchProductId: 5,
  copyChargeProductId:7,
  westFieldBank: "3861c7eb-5977-48ac-bc30-369cc77e81d6",
  baseProductExpenseId: 33,
  googleFormLink:'https://docs.google.com/forms/d/e/1FAIpQLScjpTfV18YvOmgZQvy8Vjkdt6nNu-RH82qN2djuGtD18agXAg/',
  texasT00Link:'https://drive.google.com/uc?id=18a4h2c8Oemi8u7Chw6rjE7ARVoL85zAp&export=download',
  runsheetLink:'https://drive.google.com/uc?id=11j0DdlxEuYijnLkMoghz70woneCU8Xyl&export=download',
  qualiaExpenseId: 27,
  qualia_ms_url: 'https://qualiaqa.pippintitle.com',
  wimbaCpnyId: "ccd0f7e8-bd12-49c0-a559-3e10c96502c3",
  wimbaTemplateLink: "https://drive.google.com/uc?id=1EPiGVTz_g2OQ6CUXzD8Z4TQJbCXK5bpj&export=download",
  wimbaproductId:1072,
  emailIdOptions: ['emailqa@pippintitle.com', 'emailqa2@pippintitle.com'],
  noCommitId:31
};