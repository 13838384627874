import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { CONSTANTS } from "app/app.constants";
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { RbTaxSectionComponent } from "./rb-tax-section/rb-tax-section.component";
import { TaxSectionStateService } from "./tax-section-state.service";

@Component({
  selector: 'app-rb-tax',
  templateUrl: './rb-tax.component.html',
  styleUrls: ['./rb-tax.component.scss']
})
export class RbTaxComponent implements OnInit, OnDestroy {
  @ViewChildren('section') sections!: QueryList<RbTaxSectionComponent>;
  private ngUnsubscribe = new Subject();
  sectionObject: any = {};
  searchPackage: any = {};
  sectionOptions: any;
  sectionData: any = [];
  dbSectionData: any = [];
  deletedSectionData: any = [];
  generalComments = null;
  isDragable: boolean = true;
  constants= CONSTANTS;

  constructor(private builder: RptBuilderService,private taxService:TaxSectionStateService) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) =>
          (this.sectionOptions = data ? data.SP_Tax_Entities : null)
      );
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => (this.searchPackage = data && data.dbData ? data.dbData : {})
      );
    this.builder.taxes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.generalComments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComments(data));
    this.builder.deletedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initDeletedItems(data));
  }


  initComments(data) {
    if (data && data.curData) {
      let comment = data.curData.find(
        (gc) =>
          gc.Comments_Type_ID == CONSTANTS.srchPkgItmTypes.taxes
      );
      this.generalComments = comment !== undefined ? comment : null;
    }
  }

  initDeletedItems(data) {    
    if(data){
      if(data.hasOwnProperty('SP_Taxes')) this.deletedSectionData = data['SP_Taxes'];      
    }
  }


  initComponent(data) {
    this.sectionObject = data;
    //modifiying thecondtion below because adding new assessments is not refrshing the list
    // if (data && data.curData && this.getLength() == 0) {
    if (data && data.curData && data.dbData) {
      this.sectionData = data.curData;
      this.dbSectionData = data.dbData;
    } else {
      this.sectionData = [];
      this.dbSectionData = [];
    }
   
  }

  getLength() {
    return this.sectionData ? this.sectionData.length : 0;
  }

  removeGenMessage() {
    let copyGenCom = {...this.generalComments}
    this.generalComments.Status = 0;
    this.builder.updateGeneralComment(this.generalComments, copyGenCom, this.searchPackage.Id, true).subscribe();
  }

  addGenMessage() {
    if (this.generalComments) this.generalComments.Status = 1;
    else {
      this.generalComments = {
        Id: "New",
        Sp_Id: this.searchPackage.Id,
        Comments: "",
        Comments_Type_ID: CONSTANTS.srchPkgItmTypes.taxes,
      };
    }
    this.builder.updateGeneralComment(this.generalComments, null, this.searchPackage.Id, true).subscribe();
  }

  addNewSectionItem(item, sortOrder) {
    let newItem = {
      Id: "New",
      Sp_Id: this.searchPackage.Id,
      Entity_ID: item.Id,
      Sort_Order: sortOrder,
      Derived_From: null,
      Status: 1,
      Applies: 1,
      Parcel: this.searchPackage.Parcel
    };
    let payload = {
      curData: newItem,
      dbData: null,
      Sp_Id: this.searchPackage['Id'],
      Derived_From: this.searchPackage['Derived_From']
    }
    this.builder
      .saveTax(payload, true)
      .pipe(take(1))
      .subscribe(() => { setTimeout(() => {
        const index = this.sectionData.findIndex(e=> e.Sort_Order === sortOrder)
        this.focusAddedItem(index);
      }),0});
  }

  isDragEnable(val) {
    if (val) {
      this.isDragable = false;
    } else {
      this.isDragable = true;
    }
  }

  getdbData(assessment) {
    let dbData = this.dbSectionData.find(
      (assess) => assess.Id == assessment.Id
    );
    if (dbData !== undefined) return dbData;
    return null;
  }

  dropSectionBlock(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.sectionData,
      event.previousIndex,
      event.currentIndex
    );
    this.sectionData.forEach((item, index) => {
      item.Sort_Order = index;
    });
    let payload = {
      curData: this.sectionData,
      dbData: this.dbSectionData,
      Sp_Id: this.searchPackage['Id'],
      Derived_From: this.searchPackage['Derived_From']
    }
    this.builder.updateTaxSortOrder(payload).subscribe();
  }

  focusAddedItem(index){
    if(this.sections && this.sections.length > 0){
      if(this.sections.get(index).firstInput) this.sections.get(index).firstInput.focus()
    }
  }

  focusFirsttItem(){
    if(this.sections && this.sections.length > 0){
      if(this.sections.first['firstInput']) this.sections.first['firstInput'].focus()
    }
  }
}
