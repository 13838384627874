import { Injectable } from "@angular/core";
import {
  BaseAiSectionsService,
  SectionInfo,
} from "../base-ai-sections.service";
import { Observable } from "rxjs";

@Injectable()
export class TaxSectionStateService extends BaseAiSectionsService {
    attributes = {};

getSectionInfoFromAI(sectionInfo: SectionInfo): any {
    return this.reportBuilder.getSectionDataFromAI(this.attributes, sectionInfo);
}

getTaxSectionInfoFromAI(sectionInfo: SectionInfo,entityId:number,Taxing_Authority_Type_Id:any[],Prior_Delinquencies_Type_Id:any[],tax_Source_Type_Id:any[], Tax_Paid_Status_Type_Id:any[]) : Observable<any>{
  this.attributes = this.getTaxAttributes(entityId,Taxing_Authority_Type_Id,Prior_Delinquencies_Type_Id,tax_Source_Type_Id, Tax_Paid_Status_Type_Id);
  return this.getSectionInfoFromAI(sectionInfo);
}

getTaxAttributes(entityId,Taxing_Authority_Type_Id,Prior_Delinquencies_Type_Id,tax_Source_Type_Id, Tax_Paid_Status_Type_Id){
  const paidTypeList = Tax_Paid_Status_Type_Id.map(e => e.Status_Type)
  const statusPrompt = `select any one value from this list :- ${JSON.stringify(paidTypeList)}`;
  switch (entityId) {
    case 1:
     {
      return this.getCommonAttributes({
        Annual_Amount: "",
        Annual_Paid_Date:"",
        Status: statusPrompt,
      },Taxing_Authority_Type_Id,Prior_Delinquencies_Type_Id,tax_Source_Type_Id);
     }
  
    case 2:
      {
        return this.getCommonAttributes({
          Fst_Semi_Annual_Amount: "",
          Snd_Semi_Annual_Amount: "",
          Fst_Semi_Annual_Paid_Status_Type:statusPrompt,
          Snd_Semi_Annual_Paid_Status_Type:statusPrompt,
          Fst_Semi_Annual_Paid_Date:"",
          Snd_Semi_Annual_Paid_Date:""
        },Taxing_Authority_Type_Id,Prior_Delinquencies_Type_Id,tax_Source_Type_Id);
      }
  
    case 3:
      {
        return this.getCommonAttributes({
          Fst_Quarter_Amount: "",
          Snd_Quarter_Amount: "",
          Thrd_Quarter_Amount: "",
          Frth_Quarter_Amount: "",
          Fst_Quarter_Paid_Status_Type:statusPrompt,
          Snd_Quarter_Paid_Status_Type:statusPrompt,
          Thrd_Quarter_Paid_Status_Type:statusPrompt,
          Frth_Quarter_Paid_Status_Type:statusPrompt,
          Fst_Quarter_Paid_Date:"",
          Snd_Quarter_Paid_Date:"",
          Thrd_Quarter_Paid_Date:"",
          Frth_Quarter_Paid_Date:"",
          Status: statusPrompt
        },Taxing_Authority_Type_Id,Prior_Delinquencies_Type_Id,tax_Source_Type_Id);
      }
  
    case 4:
     {
      return this.getCommonAttributes({
        Annual_Amount: "",
        Annual_Paid_Date:"",
        Status: statusPrompt,
      },Taxing_Authority_Type_Id,Prior_Delinquencies_Type_Id,tax_Source_Type_Id);
     }
  
    default:
      {
        return {}
      }
  }

}


  getCommonAttributes(additionalAttributes,Taxing_Authority_Type_Id,Prior_Delinquencies_Type_Id,tax_Source_Type_Id) {
    const taxTypeList = Taxing_Authority_Type_Id.map(e=>e.Name)
    const priorDelinquenciesList = Prior_Delinquencies_Type_Id.map(e=>e.Name)
    const taxsourceList = tax_Source_Type_Id.map(e => e.Source_Type)
    return {
      Year: "",
      Taxing_Authority: `select any one value from this list :- ${JSON.stringify(taxTypeList)}`,
      Prior_Delinquency: `select any one value from this list :- ${JSON.stringify(priorDelinquenciesList)}`,
      Source: `select any one value from this list :- ${JSON.stringify(taxsourceList)}`,
      Parcel: "",
      Language: "",
      ...additionalAttributes
    };
}

}